import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, styled } from "@mui/material";

interface DonationRouteParams {
  amount?: string | undefined;
}

const MainContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  maxWidth: '1200px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const TitleHeader = styled('h1')(({theme}) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
  width: '100%',
  textAlign: 'center',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    order: -1, // Ensure title is at the top in mobile view
    marginBottom: '10px',
  },
}));

const WidgetDivContainer = styled('div')(() => ({
  flex: 1,
  textAlign: 'center',
}));

const DivTextContainer = styled('div')(() => ({
  flex: 1,
  textAlign: 'left',
  padding: '20px',
}));

const PSubtitle = styled('p')(() => ({
  fontSize: '18px',
  color: '#555',
  margin: '10px 0',
}));

const PParagraph = styled('p')(() => ({
  fontSize: '16px',
  color: '#666',
  margin: '10px 0',
}));

const H2SectionTitle = styled('h2')(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#444',
  margin: '20px 0 10px',
}));

const OrderedList = styled('ol')(() => ({
  fontSize: '16px',
  color: '#666',
  margin: '10px 0',
  paddingLeft: '20px',
}));


const UnOrderedList = styled('ul')(() => ({
  fontSize: '16px',
  color: '#666',
  margin: '10px 0',
  paddingLeft: '20px',
}));

const Donate: React.FC = () => {
  const { amount } = useParams() as DonationRouteParams;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <ContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <TitleHeader>
              Support Be The Change Gulf Coast
            </TitleHeader>
          </Grid>          
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
              <WidgetDivContainer>
                <givebutter-widget id="gBAq6L" align="center" amount={amount ?? "2"}></givebutter-widget>
              </WidgetDivContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <DivTextContainer>
                  <PSubtitle>
                    🐝 Bee The Continuous Buzz of Hope with Be The Change Gulf Coast!
                  </PSubtitle>
                  <PParagraph>
                    Your monthly donation flowers into sustained impact, pollinating communities with hope, strength, and prosperity. When you decide to “bee” a monthly donor, you’re not just contributing funds; you’re weaving a constant network of support, enabling us to initiate, sustain, and expand our life-changing projects across the Gulf Coast.
                  </PParagraph>
                  <H2SectionTitle>
                    Bee-come a Hive of Continuous Support: Why Monthly Donations Matter
                  </H2SectionTitle>
                  <UnOrderedList>
                    <li>🐝 Steady Honey of Help: Your monthly donation guarantees a flow of funds, allowing us to plan more effectively and dive deeper into our initiatives.</li>
                    <li>🐝 Pollinate Progress: Regular support lets us swiftly mobilize resources, amplifying our reach and efficacy.</li>
                    <li>🍯 Harvest of Hope: Stable contributions help convert projects into sustained initiatives, creating a lasting positive impact.</li>
                  </UnOrderedList>
                  <H2SectionTitle>
                    How to Bee-come a Change-Maker:
                  </H2SectionTitle>
                  <OrderedList>
                    <li>Select Your Sweetness: Decide the monthly donation amount.</li>
                    <li>Buzzing Donations: Set up your secure monthly contribution in a few clicks.</li>
                    <li>Nectar of News: Stay tuned with our buzzing stories of change and resilience.</li>
                  </OrderedList>
                  <PParagraph>
                    Plant Seeds of Hope: Pledge Your Monthly Support NOW! Join us on this extraordinary journey of perpetual giving. Your small act of monthly generosity blossoms into transformative impact, creating a lush field of change, hope, and prosperity across the Gulf Coast.
                  </PParagraph>
                </DivTextContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </MainContainer>
  );
};

export default Donate;
