import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
// import beeBoarder from '../Images/beeboarder2.png'
import peopleLaughing from '../Images/PeopleLaughing.png'

const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2em"
});

// const StoryBox = styled(Grid)({
//   padding: "1em",
//   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//   background: "#fff",
//   marginBottom: "1em !important"
// });

// const MoneySpan = styled('span')({
//   fontWeight: "bold"
// });

const WorkBlock = styled(Grid)({
  padding: "1em",
});

const OurPurpose: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainGrid container>
      <Grid item xs={12}>
        <h1>Our Purpose</h1>
      </Grid>
      {/* <Grid item xs={12} lg={4} style={{ padding: "2em" }}>
        <div style={{fontSize:"1.5em"}}>Stories of those we have helped</div>
        <Grid container direction="column" alignContent="flex-start" justifyContent="flex-start" style={{ textAlign: "left" }}>
          <StoryBox item xs={12}>
            A parent, who owed day care fees because of a misunderstanding about days the children had missed, would have lost the children's spot at the day care if they were not paid. This parent would have not been able to keep their job without the <MoneySpan>$100</MoneySpan> we sent.
          </StoryBox>
          <StoryBox item xs={12}>
            Someone living in a homeless shelter obtained a new job but their car battery died and needed replacing. This person needed <MoneySpan>$194.99</MoneySpan> to prevent themselves from losing this job opportunity.
          </StoryBox>
          <StoryBox item xs={12}>
            A young parent whose car broke down in another city had to have it towed and owed <MoneySpan>$80</MoneySpan> for towing fees. We helped them not have their car towed to an impound lot.
          </StoryBox>
          <StoryBox item xs={12}>
            An individual trying to start a new job did not have the <MoneySpan>$50</MoneySpan> needed to get their required work shoes. Without our help they would have been fired from the position.
          </StoryBox>
          <StoryBox item xs={12}>
            Someone left without a home when escaping an abusive situation was without all basic necessities and we covered the <MoneySpan>$50</MoneySpan> cost of new toiletries, socks and underwear.
          </StoryBox>
          <StoryBox item xs={12}>
            A parent living in a city needed <MoneySpan>$80</MoneySpan> to purchase a bus pass for themselves and their child so that they could transport them to and from work and school, as well as doctors and grocery stores.
          </StoryBox>
        </Grid>
      </Grid>
      <Grid item><img src={beeBoarder} alt="somebeesforfun"/></Grid> */}
      <Grid item xs={12} lg={6}>
        <img src={peopleLaughing} alt="people laughing" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container justifyContent="center">
            <WorkBlock item xs={12}>
              Honeybees are known for their astounding teamwork. They might be
              tiny but by working together, each doing its own small part, these
              industrious bees build fantastic honeycombs that provide food and
              shelter for their huge families. They do not do it alone. They do
              it by working together. Each bee looks out for the good of the
              hive and the hive looks out for each bee.
            </WorkBlock>
            <WorkBlock item xs={12}>
              This is the concept of Be The Change Gulf Coast. We recognize that
              sometimes people just need a little help and by working together
              we can each do a little something to achieve the bigger goal.
            </WorkBlock>
            <WorkBlock item xs={12}>
              Be The Change Gulf Coast is a 501c3 charity committed to helping
              members of our community who find themselves struggling when an
              unexpected challenge forces them to choose between necessities.
              Founded by Shelli Bell, who through her work with several
              non-profits has seen firsthand how $100 can change the trajectory
              of a person’s life, Be The Change Gulf Coast seeks to help those
              who are trying to help themselves.
            </WorkBlock>
            <WorkBlock item xs={12}>
              The people we help are faced with hard choices like buying
              groceries or medicine. Because they can’t afford both. These are
              not luxury items; they are every day needs that a lot of us take
              for granted. For families with one or two working parents that
              just cannot make ends meet when there is a bump in the proverbial
              road, our assistance can make all the difference.
            </WorkBlock>
            <WorkBlock item xs={12}>
              Please join our hive! It’s easy to help. We have an initial
              mission to find 5,000 people in our area willing to give just $2 a
              month on auto draft. That’s right… just TWO DOLLARS per month.
              This amount is so small you’ll never miss it. But when 5,000 of us
              help each month, that’s $10,000 and THAT means a lot. Think about
              it, you’ve probably got $2.00 worth of loose change in your car’s
              cup holder right now. This small amount will barely make you blink
              but combined with others… collectively we can make a huge
              difference.
            </WorkBlock>
            <WorkBlock item xs={12}>
              Please see our personal stories tab for testimonials and please,
              work with us to help members of your community who need it. Who
              knows, maybe one day you’ll find yourself struggling in this same
              way and they will all be there to help you.
            </WorkBlock>
        </Grid>
      </Grid>      
    </MainGrid>
  );
};

export default OurPurpose;