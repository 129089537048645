import React, { useContext, useState } from "react";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../Authentication";
import { UserProfileType } from "../../Domain/UserProfile";
import { updateUserVerification } from "../../Clients";
import EnhancedTable from "../shared/EnhancedTable/EnhancedTable";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { HeadCell } from "../shared/EnhancedTable/HeadCell";
import CustomCircularProgress from "../shared/CustomCircularProgress";
import { ActionDrawerNoButton } from "../shared/ActionDrawerNoButton";
import OrganizationDrawerContent from "./OrganizationDrawerContent";
import { downloadExcel } from "../../Helpers/FileHelper";
import { ConvertFromUserProfileType } from "../../Domain/OrganizationForExport";
import { sendApprovalConfirmationEmail } from "../../Clients";

const Container = styled(Grid)(() => ({
  height: '100%',
}));

interface IOrganizations {
  userProfiles: UserProfileType[];
  isLoading: boolean;
  onStatusChange: (profileId: string, status: VerificationStatus) => void;
}

export const Organizations: React.FC<IOrganizations> = ({ userProfiles, isLoading, onStatusChange }) => {
  const user = useContext(AuthContext);
  
  const headers: HeadCell<UserProfileType>[] = [
    {
      id: "organizationName",
      label: "Organization Name",
    },
    {
      id: "representativeName",
      label: "Representative Name",
    },
    {
      id: "verificationStatus",
      label: "Status",
      customGetter: (profile: UserProfileType): string => { return VerificationStatus[profile.verificationStatus] }
    }
  ];

  const handleExport = () => {
    const now = (new Date()).toDateString();
    const fileName = `Organizations_${now}.xlsx`;
    const assistanceRequestsForDownload = userProfiles?.map(a => ConvertFromUserProfileType(a));
    if (assistanceRequestsForDownload) {
      downloadExcel(assistanceRequestsForDownload, fileName);
    }
  };

  const handleVerificationStatusChange = async (userId: string, status: VerificationStatus) => {
    await updateUserVerification(user?.accessToken!, userId, status);
    const userProfile = userProfiles.find(p => p.Id === userId);
    if (userProfile && userProfile.emailAddress) {
      sendApprovalConfirmationEmail(userProfile);
    }
    onStatusChange(userId, status);
  }
    
  const [open, setOpen] = useState<boolean>(false);
  const [currentOrgBeingViewed, setCurrentOrgBeingViewed] = useState<UserProfileType>({} as UserProfileType);

  const onRowClick = (row: UserProfileType) => {
    setCurrentOrgBeingViewed(row);
    setOpen(true);
  }

  return (
    <Container container>
      {isLoading && <Grid item xs={12} container justifyContent="center"><CustomCircularProgress message="Loading organizations list" /></Grid>}
      {!isLoading && (
        <>
          <Grid item xs={12} style={{ flex: 1 }}>
            <div className="ExportContainer" style={{ justifyContent: 'flex-end', display: 'flex' }}><Button onClick={handleExport}>Export</Button></div>
            <Grid container justifyContent="center" style={{ height: '100%' }}>
              <EnhancedTable
                rows={userProfiles}
                headCells={headers}
                title={""}
                enablePaging={true}
                enableFiltering={true}
                rowClickAction={onRowClick}
              />
              <ActionDrawerNoButton open={open} close={() => setOpen(false)} drawerContent={<OrganizationDrawerContent userProfile={currentOrgBeingViewed} onStatusChange={handleVerificationStatusChange} />} />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}