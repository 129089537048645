import { CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { AuthContext } from "../Authentication";
import { WizardStep } from "./WizardStep";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { GetSignUpWithRedirectParams } from "../../Constants/Auth0Constants";
import { RouteConstants } from "../../Constants/RouteConstants";
import { UserAuthContext } from "../../Domain/User";

const MainContainer = styled(Grid)(({ theme }) => ({
  paddingTop: "2vh",
  paddingBottom: "2vh",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1.2vw",
  lineHeight: "3vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5vh",
    lineHeight: "2vh",
  },
}));

const MainTitle = styled(Grid)(({ theme }) => ({
  fontSize: "2.5vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5vh",
  },
}));

const AllStepsContainer = styled(Grid)({
  padding: "2em",
});

const RegistrationStepContainer = styled(Grid)({
  padding: "1em",
});

enum RegistrationStepEnum {
  CreateAccount = 0,
  CompleteProfile,
  Verification,
  HellaDone,
  StuckInDenial,
}

const RegistrationSteps: React.FC = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const userFromContext = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState<RegistrationStepEnum | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMediumOrLower = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (userFromContext && !userFromContext.isLoading) {
      if (currentStep === undefined) {
        determineRegistrationStep(userFromContext);
      } else if (currentStep !== undefined) {
        if (currentStep === RegistrationStepEnum.HellaDone || userFromContext.isReadOnlyAdministrator || userFromContext.isReadWriteAdministrator) {
          navigate(RouteConstants.PortalRouter);
        }
        setIsLoading(false);
      }
    }
  }, [userFromContext, currentStep, navigate]);

  const verificationStepSubText =
    currentStep === RegistrationStepEnum.StuckInDenial
      ? "Our administrative team has carefully reviewed the information you've submitted, and we are unable to approve your request at this time."
      : "Our administrative team will now carefully review the information you've submitted and verify your status as an authorized applicant in our system.";
  const verificationStepButtonText =
    currentStep === RegistrationStepEnum.HellaDone
      ? "Verification Complete"
      : currentStep === RegistrationStepEnum.StuckInDenial
      ? "Verification Denied"
      : "Awaiting Verification";
  const verificationStepStateString =
    currentStep === RegistrationStepEnum.HellaDone
      ? "complete"
      : currentStep === RegistrationStepEnum.StuckInDenial
      ? "error"
      : currentStep === RegistrationStepEnum.Verification
      ? "inprogress"
      : "notstarted";

  const determineRegistrationStep = (userContext: UserAuthContext) => {
    if (
      userContext &&
      userContext.accessToken &&
      userContext.userProfile &&
      userContext.userProfile.verificationStatus === VerificationStatus.Approved
    ) {
      setCurrentStep(RegistrationStepEnum.HellaDone);
    } else if (
      userContext &&
      userContext.accessToken &&
      userContext.userProfile &&
      userContext.userProfile.verificationStatus === VerificationStatus.New
    ) {
      setCurrentStep(RegistrationStepEnum.Verification);
    } else if (
      userContext &&
      userContext.accessToken &&
      userContext.userProfile &&
      userContext.userProfile.verificationStatus === VerificationStatus.Denied
    ) {
      setCurrentStep(RegistrationStepEnum.StuckInDenial);
    } else if (userContext && userContext.accessToken && userContext.userProfile === undefined) {
      setCurrentStep(RegistrationStepEnum.CompleteProfile);
    } else if (userContext && userContext.accessToken === undefined) {
      setCurrentStep(RegistrationStepEnum.CreateAccount);
    }
  };

  return (
    <MainContainer container justifyContent="center">
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <MainTitle item xs={12}>
            Please complete your community provider registration.
          </MainTitle>
          <AllStepsContainer item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Grid container justifyContent={isMediumOrLower ? "center" : "space-evenly"} spacing={isMediumOrLower ? 3 : 5}>
                  <RegistrationStepContainer item sm={9} lg={4} xl={3}>
                    <WizardStep
                      stepNumber={1}
                      stepCtaTitleText="Create Your Account"
                      stepCtaSubText="Click the button below to be redirected to the signup screen. You will need an email address and password to create your account."
                      statusIconOption={(currentStep ?? 0) > 0 ? "complete" : "notstarted"}
                      currentStep={currentStep === RegistrationStepEnum.CreateAccount}
                      stepButtonText={(currentStep ?? 0) > 0 ? "Your Account Has Been Created" : "Create Your Account"}
                      stepButtonFunction={() => loginWithRedirect(GetSignUpWithRedirectParams(RouteConstants.PortalRouter))}
                    />
                  </RegistrationStepContainer>
                  <RegistrationStepContainer item sm={9} lg={4} xl={3}>
                    <WizardStep
                      stepNumber={2}
                      stepCtaTitleText="Complete Your Profile"
                      stepCtaSubText="Create your account profile. For this step, you will be asked to provide a digital copy of your tax documentation (IRS 501(c)(3) determination letter, SS4, etc.)."
                      stepButtonText={(currentStep ?? 0) > 1 ? "Profile Complete" : "Complete Your Profile"}
                      statusIconOption={(currentStep ?? 0) > 1 ? "complete" : "notstarted"}
                      currentStep={currentStep === RegistrationStepEnum.CompleteProfile}
                      stepButtonFunction={() => navigate(RouteConstants.Profile + "?navigateFromInternal=true")}
                    />
                  </RegistrationStepContainer>
                  <RegistrationStepContainer item sm={9} lg={4} xl={3}>
                    <WizardStep
                      stepNumber={3}
                      stepCtaTitleText="Wait for Verification"
                      stepCtaSubText={verificationStepSubText}
                      statusIconOption={verificationStepStateString}
                      stepButtonText={verificationStepButtonText}
                      currentStep={currentStep === RegistrationStepEnum.Verification}
                    />
                  </RegistrationStepContainer>
                </Grid>
              </Grid>
            </Grid>
          </AllStepsContainer>
        </>
      )}
    </MainContainer>
  );
};

export default RegistrationSteps;
