import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import AssistanceRequestDocumentation from "../shared/AssistanceRequestDocumentation";

export interface IAssistanceRequestListExpanded {
  assistanceRequest: AssistanceRequest;
  onDocumentationUpdated: (requestId: string, newS3Key: string) => Promise<void>;
}

const MainExpandedContainerGrid = styled(Grid)(() => ({
  paddingLeft: "1em",
  textAlign: "left",
}));

const TitleTypography = styled(Typography)(() => ({  
  fontSize: 16,
  fontWeight: "bold",
  display: "inline",
}));


const ValueTypography = styled(Typography)(() => ({
  fontSize: 16,
  display: "inline",
}));

const AssistanceRequestListExpanded: React.FC<IAssistanceRequestListExpanded> = ({assistanceRequest, onDocumentationUpdated}) => {

  return (   
    <MainExpandedContainerGrid container>
      <Grid item xs={6}>
        <TitleTypography>SubmittedBy: </TitleTypography>
        <ValueTypography>{assistanceRequest.representativeName}</ValueTypography>
      </Grid>
      <Grid item xs={6}>
        <TitleTypography>Purpose: </TitleTypography>
        <ValueTypography>{assistanceRequest.purpose}</ValueTypography>
      </Grid>        
      <Grid item xs={6}>
        <TitleTypography>Requested Distribution Date: </TitleTypography>
        <ValueTypography>{assistanceRequest.requestedDistributionDate}</ValueTypography>
      </Grid>
      {assistanceRequest.projectCompletionDate && (
        <Grid item xs={6}>
          <TitleTypography>Project Completion Date: </TitleTypography>
          <ValueTypography>{assistanceRequest.projectCompletionDate}</ValueTypography>
        </Grid>
      )}
      <AssistanceRequestDocumentation assistanceRequestId={assistanceRequest.Id} assistanceRequestS3Key={assistanceRequest.s3Key} onDocumentationUpdated={onDocumentationUpdated}/>
    </MainExpandedContainerGrid>
  );
};
export default AssistanceRequestListExpanded;
