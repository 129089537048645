import { Divider, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { MenuItemType } from "../../Domain/MenuItem";

export interface ICustomMenu {
    name: string;
    menuButton?: React.ReactElement | React.ReactElement[]
    menuItems: MenuItemType[]
    onClose?: () => void;
}

export const CustomMenu:React.FC<ICustomMenu> = ({name, menuButton, menuItems, onClose}) => {

    const [anchorElement, SetAnchorElement] = React.useState<null | HTMLElement>(null);
        
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        SetAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        SetAnchorElement(null);
         if(onClose){
            onClose();
        }
    };

    return (
        <>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                aria-controls={name + "-id"}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {menuButton && <>{menuButton}</>}
            </IconButton>
            <Menu                
                id={name + "-id"}
                anchorEl={anchorElement}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={handleClose}
            >
                {menuItems.map(menuItem => {
                        return (
                            <div key={menuItem.label + "menuKey"}>
                                {menuItem.dividerAbove && <Divider/>}
                                <MenuItem onClick={() => menuItem.subMenu ? {} : handleClose()}>
                                    {!!menuItem.subMenu && (
                                        <>{React.cloneElement(menuItem.subMenu, {onClose: () =>{handleClose()}})}</>
                                    )}
                                    {!menuItem.subMenu && (
                                        <Grid item onClick={() => menuItem.onClick ? menuItem.onClick() : {}}>
                                            {menuItem.label}
                                        </Grid>
                                    )}
                                </MenuItem>
                                {menuItem.dividerBelow && <Divider/>}
                            </div>
                        );
                    })}
            </Menu>
        </>
    );
} 