import { Button, CircularProgress, Box, ButtonGroup } from "@mui/material";
import { ButtonType } from "./ButtonType";

export interface IButtonGroupWithLoading {
    buttons: ButtonType[];
    isLoading?: boolean;
}

const ButtonGroupWithLoading: React.FC<IButtonGroupWithLoading> = ({ buttons, isLoading }) => {
    return (
        <Box position="relative" display="inline-flex" alignItems="center">
            <ButtonGroup size="small" aria-label="small button group" color="primary" variant="contained">
                {buttons && buttons.map((button, index) => (
                    <Button
                        key={"button#" + index}
                        onClick={button.onClick}
                        disabled={isLoading || button.disable}
                        color={button.color ?? "primary"}
                    >
                        {button.label}
                    </Button>
                ))}
            </ButtonGroup>
            {isLoading && (
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    style={{ transform: 'translate(-50%, -50%)' }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default ButtonGroupWithLoading;
