import React, { useContext } from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../Constants/RouteConstants";
import { GetLoginWithRedirectParams, GetSignUpWithRedirectParams } from "../../Constants/Auth0Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthContext } from "../Authentication";

const StandardNavBar: React.FC = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const {loginWithRedirect} = useAuth0();
    
    return (        
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item style={{cursor:"pointer"}} onClick={() => navigate(RouteConstants.Home)}>Home</Grid>
            <Grid item style={{cursor:"pointer"}} onClick={() => navigate(RouteConstants.OurPurpose)}>Our Purpose</Grid>
            <Grid item style={{cursor:"pointer"}} onClick={() => navigate(RouteConstants.Board)}>Our Board</Grid>
            <Grid item style={{cursor:"pointer"}} onClick={() => navigate(RouteConstants.Contact)}>Contact Us</Grid>
            <Grid item style={{cursor:"pointer"}}>
                <Grid container>
                    <Grid item xs={12} onClick={() => navigate(RouteConstants.PortalRouter)}>Community Provider Portal</Grid>
                    {!user?.accessToken && (<Grid item xs={12}>
                        <Grid container justifyContent="center" style={{fontSize:"1.5vh", padding:"none"}}>
                            <Grid item>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    bgcolor: 'transparent',
                                    padding:"none",
                                    '& svg': {
                                        m: 1.5,
                                    },
                                    '& hr': {
                                        mx: 0.5,
                                    },
                                    }}
                                >
                                    <Grid item onClick={() => loginWithRedirect(GetSignUpWithRedirectParams(RouteConstants.PortalRouter))} style={{cursor:"pointer", paddingRight:".4em"}}>Sign Up</Grid>
                                    <Divider orientation="vertical" flexItem style={{backgroundColor:"white"}} />
                                    <Grid item onClick={() => loginWithRedirect(GetLoginWithRedirectParams(RouteConstants.PortalRouter))} style={{cursor:"pointer", paddingLeft:".4em"}}>Log In</Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>)}
                </Grid>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    sx={{color: "black", backgroundColor: "#FEC440", fontSize: "inherit"}}
                    onClick={() => navigate(RouteConstants.Donate)}
                >
                    Donate
                </Button>
            </Grid>
        </Grid>
    );
};

export default StandardNavBar;