import { IdToken } from "@auth0/auth0-react";

export function checkIsAdmin(claims: IdToken | undefined): boolean{
    var rolePathOnClaim = process.env.REACT_APP_AUTH0_AUDIENCE + "/roles";
    if(claims && claims[rolePathOnClaim] && claims[rolePathOnClaim][0] === "BTCAdmin"){
        return true;
    }
    else {
        return false;
    }
}

export function checkIsReadOnlyAdmin(claims: IdToken | undefined): boolean{
    var rolePathOnClaim = process.env.REACT_APP_AUTH0_AUDIENCE + "/roles";
    if(claims && claims[rolePathOnClaim] && claims[rolePathOnClaim][0] === "BTCReadOnlyAdmin"){
        return true;
    }
    else {
        return false;
    }
}