import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

const OuterDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  maxWidth: '1200px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
  width: '100%',
  textAlign: 'center',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    order: -1,
    marginBottom: '10px',
  },
}));

const StateDisclosures: React.FC = () => {
  return (
    <OuterDiv>
      <Content>
        <Grid container>
          <Grid item xs={12}>
            <Title variant="h1">Be The Change Gulf Coast</Title>
            State disclosure information is being processed and will be available shortly
          </Grid>
        </Grid>
      </Content>
    </OuterDiv>
  );
};

export default StateDisclosures;
