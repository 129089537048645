import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

const ExtraExtraLongInputBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const MediumLongInputBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
  [theme.breakpoints.up("md")]: {
    width: "20vw",
  },
}));

const ShortInputBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.up("md")]: {
    width: "10vw",
  },
}));

interface IDateInput {
    label: string;
    value?: string;
    onChange?: (value: Date | undefined) => void;
    size?: 'short' | 'medium' | 'wide';
    disablePast?: boolean;
    errorMessage?: string;
    showError?: boolean;
    required?: boolean;
}

export const DateInput = ({label, value, onChange, disablePast, size = 'wide', errorMessage, showError, required}: IDateInput) => {
    
    const getInputBoxComponent = () => {
        switch (size) {
            case 'medium':
                return MediumLongInputBox;
            case 'wide':
                return ExtraExtraLongInputBox;
            case 'short':
                return ShortInputBox;
            default:
                return 'div';
        }
    };

    const InputBoxComponent = getInputBoxComponent();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <InputBoxComponent>
                <DatePicker
                    label={label}
                    value={dayjs(value)}
                    onChange={(newValue) => {
                        if(onChange){
                            onChange(newValue?.toDate());
                        }
                    }}
                    disablePast={disablePast}
                    slotProps={{
                        textField: {
                            helperText: showError ? errorMessage : "",
                            required: required
                        },
                    }}                
                />
            </InputBoxComponent>
        </LocalizationProvider>
    );
}
