import { ButterTransaction } from "../Domain/ButterInfo";
import { DonationAssistanceMonth } from "../Domain/Donation";

export function allTransactionTotalsToDonationAsssistanceMonths(donationAssistanceMonths: DonationAssistanceMonth[], transactions: ButterTransaction[]): DonationAssistanceMonth[] {
    donationAssistanceMonths.forEach(donationAssistanceMonth => {
        donationAssistanceMonth.donationsRecieved = getMonthTotalDonated(donationAssistanceMonth.monthNumber, donationAssistanceMonth.yearNumber, transactions);
    })
    return donationAssistanceMonths;
}

function getMonthTotalDonated(month: number, year: number, transactions: ButterTransaction[]): number {
    return transactions
        .filter(t => isDateInMonthAndYear(t.transacted_at, month, year))
        .reduce((sum, p) => sum + parseFloat(p.amount), 0) || 0;
}

function isDateInMonthAndYear(dateString: string, month: number, year: number): boolean {
    // Parse the date string into a Date object
    const date = new Date(dateString);
    
    // Get the month and year from the Date object
    const dateMonth = date.getMonth() + 1; // getMonth() returns 0-11, so add 1
    const dateYear = date.getFullYear();
    
    // Check if the month and year match
    return dateMonth === month && dateYear === year;
}