import React, { useState } from "react";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { ApplicationStatus } from "../../Domain/ApplicationStatus";
import CustomCircularProgress from "../shared/CustomCircularProgress";
import EnhancedTable from "../shared/EnhancedTable/EnhancedTable";
import { HeadCell } from "../shared/EnhancedTable/HeadCell";
import { ActionDrawerNoButton } from "../shared/ActionDrawerNoButton";
import AssistanceRequestDrawerContent from "./AssistanceRequestDrawerContent";
import { Button } from "@mui/material";
import { ConvertFromAssistanceRequest } from "../../Domain/AssistanceRequestForExport";
import { downloadExcel } from "../../Helpers/FileHelper";

interface IAdminAssistanceRequestsProps {
    requestList: AssistanceRequest[];
    isLoading: boolean;
    onStatusUpdate: (requestId: string, status: ApplicationStatus, completionDate?: string, payoutNotes?: string) => Promise<void>;
    onDocumentationUpdated: (requestId: string, newS3Key: string) => Promise<void>;
}

const AdminAssistanceRequests: React.FC<IAdminAssistanceRequestsProps> = ({requestList, isLoading, onStatusUpdate, onDocumentationUpdated}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [currentRequestBeingViewed, setCurrentRequestBeingViewed] = useState<AssistanceRequest>({} as AssistanceRequest);

    const onStatusChanged = async (requestId: string, status: ApplicationStatus, completionDate?: string, payoutNotes?: string) => {
        onStatusUpdate(requestId, status, completionDate, payoutNotes).then(() => setOpen(false));
    }

    const onRowClick = (row: AssistanceRequest) => {
        setCurrentRequestBeingViewed(row);
        setOpen(true);
    }
    
    const handleExport = () => {
        var now = (new Date()).toDateString();
        var fileName = `AssistanceRequests_${now}.xlsx`;
        var assistanceRequestsForDownload = requestList?.map(a => ConvertFromAssistanceRequest(a));
        if(assistanceRequestsForDownload){
            downloadExcel(assistanceRequestsForDownload, fileName);
        }
    };
    
    const headers: HeadCell<AssistanceRequest>[] = [
        {
        id: "organizationName",
        label: "Organization Name",
        isAlertColumn: true,
        },
        {
        id: "amountRequested",
        label: "Amount Requested",
        isDollarAmount: true,
        },
        {
        id: "requestDate",
        label: "Date Requested",
        },
        {
        id: "formattedStatus",
        label: "Status",
        },
    ];

    return (
        <>
            {isLoading && <CustomCircularProgress message="Loading assistance request list" />}
            {!isLoading && (
                <>
                    <div className="ExportContainer" style={{justifyContent:'flex-end', display:'flex'}}><Button onClick={handleExport}>Export</Button></div>
                    <EnhancedTable
                        rows={requestList}
                        headCells={headers}
                        initialOrderBy="requestDate"
                        enableFiltering={true}
                        enablePaging={true}
                        rowClickAction={onRowClick}
                    />
                    <ActionDrawerNoButton open={open} close={() => setOpen(false)} drawerContent={<AssistanceRequestDrawerContent assistanceRequest={currentRequestBeingViewed} onStatusChange={onStatusChanged} onDocumentationUpdated={onDocumentationUpdated}/>}/>
                </>
            )}
        </>
    );
};

export default AdminAssistanceRequests;
