import React, { useState } from "react";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { Modal, Button, Box, Typography, Grid, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { DateInput } from "../shared/DateInput";
import { adjustMonths, dateIsBetween, formatDate } from "../../Helpers/DateHelper";
import AssistanceRequestForExport, { ConvertFromAssistanceRequest } from "../../Domain/AssistanceRequestForExport";
import { ExportType } from "../shared/ExportType";
import ReportElement from "../shared/ReportElement";

interface IAssistanceRequestReport{
    assistanceRequests?: AssistanceRequest[];
    reportName?: string;
    byDateRange?: boolean;
    byOrganization?: boolean;
}

const MainSpan = styled('span')(() => ({
    fontSize: "1em",
    height: "100%",
  }));

const ReportLinkDiv = styled('div')(({ theme }) => ({
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline",
      color: theme.palette.primary.main, // Change this to the color you want on hover
    }
  }));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const AssistanceRequestReport: React.FC<IAssistanceRequestReport> = ({assistanceRequests, reportName, byDateRange, byOrganization}) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const handleOpen = () => setShowModal(true);
    const handleClose = () => {reset(); setShowModal(false);}

    const currentDate = new Date();
    const threeMonthsBack = new Date();
    threeMonthsBack.setMonth(adjustMonths(currentDate.getMonth(), -3));

    const [startRange, setStartRange] = useState<string>(formatDate(threeMonthsBack));
    const [endRange, setEndRange] = useState<string>(formatDate(currentDate));

    const uniqueOrganizations = Array.from(new Set(assistanceRequests?.map(ar => ar.organizationName)));
    const [organizationsList] = useState<string[]>(uniqueOrganizations);
    const [selectedOrganization, setSelectedOrganization] = useState<string>("");


    const onStartDateChange = (newDate: Date | undefined) => {
        if(newDate){
            setStartRange(newDate.toDateString());
        }
    };

    const onEndDateChange = (newDate: Date | undefined) => {
        if(newDate){
            setEndRange(newDate.toDateString());
        }
    };

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedOrganization(event.target.value as string);      
    };

    const reset = () => {
        setSelectedOrganization("");
        setStartRange(formatDate(threeMonthsBack));
        setEndRange(formatDate(currentDate));
    }

    const getData = ():AssistanceRequestForExport[] => {
        var filteredResults = [] as AssistanceRequestForExport[];
        if (byDateRange && !byOrganization && assistanceRequests) {
            filteredResults = assistanceRequests.filter(a => dateIsBetween(a.requestDate, startRange, endRange)).map(a => ConvertFromAssistanceRequest(a));
        } else if (byOrganization && !byDateRange && assistanceRequests && selectedOrganization) {
            filteredResults = assistanceRequests.filter(ar => ar.organizationName === selectedOrganization).map(a => ConvertFromAssistanceRequest(a));
        } else if (byDateRange && byOrganization && assistanceRequests && selectedOrganization) {
            filteredResults = assistanceRequests
                .filter(a => dateIsBetween(a.requestDate, startRange, endRange) && a.organizationName === selectedOrganization)
                .map(a => ConvertFromAssistanceRequest(a));
        }
        
        if (filteredResults) {
            return filteredResults;
        }
        else {
            return [];
        }
    }

    return (
        <MainSpan>
            <div>
                <ReportLinkDiv onClick={() => handleOpen()}>
                    {reportName}
                </ReportLinkDiv>
            </div>
            <Modal
                open={showModal}
                onClose={handleClose}
                aria-labelledby="export-modal"
                aria-describedby="Export the assistance requests"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: 'blur(3px)',
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Export Assistance Requests
                    </Typography>
                    {byDateRange && (
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Select the date range
                            </Typography>
                            <div style={{padding:".5em"}}><DateInput label="Start Date" size="short" value={startRange} onChange={onStartDateChange}/></div>
                            <div style={{padding:".5em"}}><DateInput label="End Date" size="short" value={endRange} onChange={onEndDateChange}/></div>                            
                        </>
                    )}
                    {byOrganization && (
                        <>
                            <FormControl fullWidth style={{padding:".5em"}}>
                                <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedOrganization}
                                    label="Organization"
                                    onChange={handleChange}
                                >
                                    {organizationsList.map(or => 
                                        <MenuItem value={or} key={or}>{or}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </>
                    )}
                    <Grid container spacing={1}>
                        <Grid item>
                            <ReportElement getRecords={getData} label="Export" exportType={ExportType.excel} clickableElement="button" fileNameForDownload="AssistanceRequestReport" postDownloadTrigger={handleClose}/>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClose} variant="contained">
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </MainSpan>
    );
};

export default AssistanceRequestReport;