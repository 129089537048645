export const GetLoginWithRedirectParams = (navigation: string) => {
    return {
        appState: {
            returnTo: navigation
        },
    };    
}

export const GetSignUpWithRedirectParams = (navigation: string) => {
    return {
        appState: {
            returnTo: navigation
        },
        authorizationParams: {
            screen_hint: "signup"
        }
    };
}

export const GetLogOutWithRedirectParams = (navigation: string) => {
    return {
        logoutParams: {
            returnTo: navigation
        }
    };
}