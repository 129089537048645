import React, { useContext, useEffect, useState } from "react";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import {  getAssistanceRequests } from "../../Clients";
import { AuthContext } from "../Authentication";
import { CircularProgress } from "@mui/material";
import AssistanceRequestListExpanded from "./AssistanceRequestListExpanded";
import { HeadCell } from "../shared/EnhancedTable/HeadCell";
import EnhancedTable from "../shared/EnhancedTable/EnhancedTable";

const AssistanceRequests: React.FC = () => {
  const user = useContext(AuthContext);  
  const [requestList, setRequestList] = useState<AssistanceRequest[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        if (user?.accessToken) {
          const requests = await getAssistanceRequests(user.accessToken);
          setRequestList(requests);
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch assistance requests:", error);
        setLoading(false);
      }
    };
    fetchRequests();
  }, [user]);

  const onDocumentationUpdated = async (requestId: string, newS3Key: string) => {
    const request = requestList.find((r) => r.Id === requestId);
    if(user?.accessToken && request){      
      const updatedRequestList = requestList.map((r) =>
        r.Id === requestId ? { ...r, S3Key: newS3Key } : r
      );
      setRequestList(updatedRequestList);
    }
  };
      
  const getExpandedData = (request: AssistanceRequest) => {
    return (
      <AssistanceRequestListExpanded
        assistanceRequest={request}
        onDocumentationUpdated={onDocumentationUpdated}
      />
    );
  };

  const headers: HeadCell<AssistanceRequest>[] = [
    {
      id: "representativeName",
      label: "Submitted By",
      isAlertColumn: true,
    },
    {
      id: "amountRequested",
      label: "Amount Requested",
      isDollarAmount: true,
    },
    {
      id: "requestDate",
      label: "Date Requested",
    },
    {
      id: "formattedStatus",
      label: "Status",
    },
  ];

  return (
    <>    
      {loading && <CircularProgress />}
      {!loading && user?.userProfile && (
        <EnhancedTable
          rows={requestList}
          headCells={headers}
          initialOrderBy="requestDate"
          expandSection={getExpandedData}
          enableFiltering={false}
          enablePaging={true}
        />
      )}
    </>
  );
};

export default AssistanceRequests;