import FileListContainer from "./FileListContainer";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const FileUploadSection = styled(Grid)(() => ({
  paddingBottom: "2em",
  paddingTop: "2em",
}));

const ErrorWarningMessage = styled(Grid)(() => ({
  color: "red",
  textAlign: "left",
}));

interface IFileUpload {
    fileList: File[];
    buttonText: string;
    onFilesAdded: (newFiles: File[]) => void;
    onFileDelete?: (deletedFile: File) => void;
    allowDelete?: boolean;
    uploadErrorOrWarningMessage?: string;
    uploadInstructionMessage?: string;
}

const FileUpload: React.FC<IFileUpload> = ({fileList, onFilesAdded, onFileDelete, allowDelete, uploadErrorOrWarningMessage, uploadInstructionMessage, buttonText}: IFileUpload) => {

    const deleteFile = (index: number) => {
        if(onFileDelete && allowDelete){
            onFileDelete(fileList[index]);
        }
    };

    const fileUploadChange = (e: any) => {
        const passedFileArray = Array.from(e.target.files) as File[];
        onFilesAdded(passedFileArray);
    };

    return (
        <FileUploadSection item xs={12}>
            <Grid container justifyContent="flex-start">
                {uploadErrorOrWarningMessage && (
                    <ErrorWarningMessage item xs={12}>
                        {uploadErrorOrWarningMessage}
                    </ErrorWarningMessage>
                )}
                <Grid item>
                    {uploadInstructionMessage && (<span>{uploadInstructionMessage}</span>)}
                    <Button variant="contained" component="label" htmlFor="raised-button-file" style={{ marginLeft: "1em" }}>
                        {buttonText}
                        <input type="file" hidden id="raised-button-file" multiple onChange={fileUploadChange} />
                    </Button>
                </Grid>
            </Grid>
            {fileList && <FileListContainer fileList={fileList} onDelete={deleteFile} allowDelete={allowDelete}/>}
        </FileUploadSection>
    );
}
export default FileUpload;
