import React, { useState } from 'react';
import { Member } from '../../Domain/BoardMember';
import BoardMemberCardContents from './BoardMemberCardContents';
import BoardMemberCardModal from './BoardMemberCardModal';
import { Grid, styled } from '@mui/material';

const MemberCard = styled(Grid)(() => ({
  border: '1px solid #ddd',
  borderRadius: '8px',
  padding: '1em',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
  textAlign: 'left',
  minHeight:"100%"
}));


const BoardMemberCard: React.FC<{ member: Member }> = ({ member }) => {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <MemberCard container>
        <Grid item xs={12}>
          <BoardMemberCardContents member={member} moreClick={handleOpen}/>
        </Grid>
        <BoardMemberCardModal member={member} open={open} closeTriggered={handleClose}/>
      </MemberCard>
    );
  };

  export default BoardMemberCard;