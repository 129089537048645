
import { Authentication } from './Components/Authentication';
import { createTheme, styled, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './Components/Footer';
import { AppState, Auth0Provider, User as Auth0User } from '@auth0/auth0-react';
import { AllRoutes } from './Components/AllRoutes';
import { createBrowserHistory } from 'history';
import NavBarContainer from './Components/Navbar/NavBarContainer';
import { PortalBanner } from './Components/Portal/PortalBanner';

require("typeface-bad-script");
require("typeface-roboto");
require("typeface-abril-fatface");
require("typeface-dm-serif-display");

const theme = createTheme({
  palette: {
    primary: {
      main: "#92288F",
      light: "#da9101",
      contrastText: "#FFF",
    },
    secondary: {
      contrastText: "#FEC440",
      main: "#fed337",
      light: "#E8E9EB",
    },
    warning: {
      main: "#dedede",
    },
  }
});

const OuterDivForWholeDamnThing = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  cursor:"default"
});



function App() {
  const domain = process.env.REACT_APP_DOMAIN;
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!(auth0Domain && clientId && audience)) {
    return <>This thing is broke</>;
  }

  const history = createBrowserHistory();
  //eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const onRedirectCallback = (appState: AppState | undefined, user: Auth0User | undefined) => {
    console.log("got here");
    console.log(appState);
    console.log(user);
    if(appState && appState.returnTo){
      window.location.href = `${domain}${appState?.returnTo}`
    }
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: domain,
        audience: audience,
        scope: "write:client read:client write:admin read:admin email",
      }}
    >
      <Authentication>
            <ThemeProvider theme={theme}>
              <OuterDivForWholeDamnThing>
                <Router>
                  <NavBarContainer />
                  <PortalBanner />
                  <AllRoutes />
                  <Footer />
                </Router>
              </OuterDivForWholeDamnThing>
            </ThemeProvider>
      </Authentication>
    </Auth0Provider>
  );
}

export default App;
