import React from "react";
import { Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";
import beeButton from "../../Images/bethechange--bee in honeycomb-03.png";
import { RouteConstants } from "../../Constants/RouteConstants";

const BeeDonateButtonContainer = styled(Grid)(() => ({
  fontFamily: "Roboto",
  fontWeight: 400,
  textAlign: "center",
}));

const BeeDonateButtonImg = styled('img')(({ theme }) => ({
  width: "10vw",
  [theme.breakpoints.down("md")]: {
    width: "20vw",
  },
}));

const BeeDonateButtonText = styled(Grid)(({ theme }) => ({
  fontSize: "1.75vw",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.75vw",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "3.75vw",
  },
}));

interface IDonationBeeButton {
  buttonMessage: string;
  donationAmount: string;
}

export const DonationBeeButton: React.FC<IDonationBeeButton> = ({ buttonMessage, donationAmount }) => {
  return (
    <Link to={RouteConstants.Donate + `/${donationAmount}`} style={{ textDecoration: "none", color: "inherit" }}>
      <BeeDonateButtonContainer container direction="column">
        <Grid item xs={12}>
          <BeeDonateButtonImg src={beeButton} alt="Be The Change, GulfCoast" />
        </Grid>
        <BeeDonateButtonText item xs={12}>
          {buttonMessage}
        </BeeDonateButtonText>
      </BeeDonateButtonContainer>
    </Link>
  );
}
