import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react";

export interface IFileListContaner {
  fileList: File[];
  onDelete: (index: number) => void;
  allowDelete?: boolean;
}

const FileListContaner: React.FC<IFileListContaner> = ({fileList, allowDelete, onDelete}: IFileListContaner) => {
  return (
    <List dense={true}>
      {fileList.map((file, i) => {
        return (
          <ListItem key={"FileNumber" + i}>
            {allowDelete && (
              <ListItemIcon>
                <DeleteIcon onClick={() => onDelete(i)} />
              </ListItemIcon>
            )}
            <ListItemText primary={file.name} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default FileListContaner;
