import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";
import React from "react";
import { HeadCell } from "./HeadCell";
import { Order } from "./Order";
import { styled } from "@mui/material/styles";

const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: 13,
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: 10,
  },
}));

const VisuallyHiddenSpan = styled('span')({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
});

export interface EnhancedTableProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy?: keyof T | undefined;
  headCells: HeadCell<T>[];
  hasExpandedSection?: boolean;
}

export default function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.hasExpandedSection && (<TableCell style={{ width: "2%" }}></TableCell>)}
        {props.headCells.map((headCell) => (
          <HeaderCell
            key={headCell.id.toString()}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <VisuallyHiddenSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</VisuallyHiddenSpan>
              ) : null}
            </TableSortLabel>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}