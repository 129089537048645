import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { MenuItemType } from "../../Domain/MenuItem";
import { CustomMenu } from "../shared/CustomMenu";
import { RouteConstants } from "../../Constants/RouteConstants";
import { AuthContext } from "../Authentication";

const MobileNavBar: React.FC = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    
    const handleLogout = () => {
        user?.logOut?.(window.location.origin);
    }

    const constructMenuItems = ():MenuItemType[] => {
        var menuItems = [] as MenuItemType[];
        menuItems.push({name: "donate", label: "Donate", onClick: () => navigate(RouteConstants.Donate), dividerBelow: true} as MenuItemType);
        menuItems.push({name: "home", label: "Home", onClick: () => navigate(RouteConstants.Home)} as MenuItemType);
        menuItems.push({name: "purpose", label: "Our Purpose", onClick: () => navigate(RouteConstants.OurPurpose)} as MenuItemType);
        menuItems.push({name: "board", label: "Our Board", onClick: () => navigate(RouteConstants.Board)} as MenuItemType);
        menuItems.push({name: "contact", label: "Contact Us", onClick: () => navigate(RouteConstants.Contact), dividerBelow: true} as MenuItemType);
        menuItems.push({name: "portal", label: "Community Provider Portal", onClick: () => navigate(RouteConstants.PortalRouter)} as MenuItemType);
        if(user?.accessToken){
            menuItems.push({name: "logout", label: "Log Out", onClick: handleLogout} as MenuItemType);
        }
        return menuItems;
    }

    return (        
        <Grid container>
            <Grid item>
                <CustomMenu name="mainMenu" menuItems={constructMenuItems()} menuButton={<MenuIcon />}/>
            </Grid>
        </Grid>
    );
};

export default MobileNavBar;