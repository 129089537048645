import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const TableTitleContainer = styled(Grid)(() => ({
  textAlign: "center",
}));

interface ITableTitle {
  titleText: string;
}

export const TableTitle: React.FC<ITableTitle> = ({ titleText }) => {
  return (
    <Grid container>
      <TableTitleContainer item xs={12}>
        <Typography variant="h6" id="tableTitle" component="div">
          {titleText}
        </Typography>
      </TableTitleContainer>
    </Grid>
  );
};
