import { Grid, styled } from "@mui/material";
import React from "react";
import WineTasting  from "../Images/winetasting.png"
import DrHortonLogo from "../Images/drhortonlogo.png"
import PrincipalLogo from "../Images/principallogo.png"
import AlanCoLogo from "../Images/alanandco.jpg"

const ThankYouBannerMain = styled(Grid)(({ theme }) => ({
  minHeight:"10vh",
  backgroundColor:"#ba6eb7",
  color:"#333333",
  fontFamily: "Yellowtail",
  fontSize: "4em",
  padding:".5em",
  [theme.breakpoints.down("md")]: {
    fontSize: "2em",
  },
}));

const EventImage = styled('img')(({ theme }) => ({
  width: '90%',
  height: 'auto',
  borderRadius: '8px', // Example: Adding a border radius
}));

const SponsorImage = styled('img')(({ theme }) => ({
  width: '90%',
  height: 'auto',
  borderRadius: '8px', // Example: Adding a border radius
}));

const EventThankYouBanner: React.FC = () => {
  return (
    <Grid container justifyContent="center" alignContent="center">
      <ThankYouBannerMain item xs={12}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} sm={2} alignContent="center"><EventImage src={WineTasting}/></Grid>
          <Grid item xs={12} sm={10} alignContent="center">
            <Grid container>
              <Grid item xs={12} style={{paddingBottom:".25em"}}>Thank you to our sponsors for the success of our Wine Tasting Under The Stars fundraiser!</Grid>
              <Grid item xs={12} sm={4}><SponsorImage src={DrHortonLogo}/></Grid>
              <Grid item xs={12} sm={4}><SponsorImage src={PrincipalLogo}/></Grid>
              <Grid item xs={12} sm={4}><SponsorImage src={AlanCoLogo}/></Grid>
            </Grid></Grid>
        </Grid>
      </ThankYouBannerMain>
    </Grid>
  );
};

export default EventThankYouBanner;
