import { ApplicationStatus } from "./ApplicationStatus";
import { BooleanType, NullType, NumberType, StringType, checkBoolean, checkEnum, checkNumber, checkString } from "./DynamoDbDomain";

export default class AssistanceRequest {
  Id: string = "";
  organizationName: string = "";
  representativeName: string = "";
  amountRequested: number = 0.0;
  purpose: string = "";
  requestedDistributionDate: string = "";
  projectCompletionDate?: string = "";
  signatureName: string = "";
  signatureOrganization: string = "";
  agreedToTerms: boolean = false;
  requestDate: string = "";
  userId: string = "";
  status?: ApplicationStatus;
  s3Key?: string;
  formattedStatus: string = this.status ? ApplicationStatus[this.status] : "";
  payoutNotes?: string = "";
}

type AssistanceRequestServerModel = {  
  Id: StringType,
  organizationName: StringType | NullType,
  representativeName: StringType | NullType,
  amountRequested: NumberType | NullType;
  purpose: StringType | NullType,
  requestedDistributionDate: StringType | NullType,
  projectCompletionDate: StringType | NullType,
  signatureName: StringType | NullType,
  signatureOrganization: StringType | NullType,
  agreedToTerms: BooleanType | NullType,
  requestDate: StringType | NullType,
  userId: StringType | NullType,
  status: NumberType | NullType,
  s3Key: StringType | NullType,
  payoutNotes: StringType | NullType
}

export const ConvertServerModelToAssistanceRequest = (serverModel: AssistanceRequestServerModel): AssistanceRequest => {
  var status = checkEnum<ApplicationStatus>(serverModel.status);
  return {
    Id: serverModel.Id.S,
    organizationName: checkString(serverModel.organizationName),
    representativeName: checkString(serverModel.representativeName),
    amountRequested: checkNumber(serverModel.amountRequested),
    purpose: checkString(serverModel.purpose),
    requestedDistributionDate: checkString(serverModel.requestedDistributionDate),
    projectCompletionDate: checkString(serverModel.projectCompletionDate),
    signatureName: checkString(serverModel.signatureName),
    signatureOrganization: checkString(serverModel.signatureOrganization),
    agreedToTerms: checkBoolean(serverModel.agreedToTerms),
    requestDate: checkString(serverModel.requestDate),
    userId: checkString(serverModel.userId),
    s3Key: checkString(serverModel.s3Key),
    status: status,
    formattedStatus: status ? ApplicationStatus[status] : "",
    payoutNotes: checkString(serverModel.payoutNotes)
  } as AssistanceRequest
}

export const MapListFromApiItems = (requests: AssistanceRequestServerModel[]) => {
  return requests.map(request => ConvertServerModelToAssistanceRequest(request));
}