import React from "react";
import { Button, Grid, Paper } from "@mui/material";
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from "@mui/material/styles";

const WizardStepContainer = styled(Paper)(() => ({
  padding: "1em",
}));

const WizardStepTitle = styled(Grid)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.5vh",
  lineHeight: "2vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5vh",
    lineHeight: "2vh",
  },
}));

const WizardStepCtaSubText = styled(Grid)(({ theme }) => ({
  fontSize: "1.5vh",
  lineHeight: "1em",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5vh",
    lineHeight: "2vh",
  },
  paddingTop: ".5em",
}));

const WizardStepButtonContainer = styled(Grid)(() => ({
  padding: "1em",
}));

interface IWizardStep {
  stepNumber: number;
  stepCtaTitleText: string;
  stepCtaSubText?: string;
  stepButtonText?: string;
  stepButtonFunction?: () => void;
  statusIconOption?: "notstarted" | "inprogress" | "complete" | "error";
  currentStep?: boolean;
}

export const WizardStep: React.FC<IWizardStep> = ({
  stepNumber,
  stepCtaTitleText,
  stepCtaSubText,
  stepButtonText,
  statusIconOption,
  stepButtonFunction,
  currentStep = false
}) => {
  return (
    <WizardStepContainer elevation={4}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignContent="center">
            <WizardStepTitle item xs={12}>
              Step {stepNumber}: {stepCtaTitleText}
            </WizardStepTitle>
            <WizardStepCtaSubText item xs={12}>
              {stepCtaSubText}
            </WizardStepCtaSubText>
          </Grid>
        </Grid>
        <WizardStepButtonContainer item xs={12}>
          {!stepButtonFunction && stepButtonText && (
            <Button
              variant="outlined"
              disabled={!currentStep}
              disableRipple
              disableFocusRipple
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                  cursor: "inherit",
                  border: "1px solid rgba(25, 118, 210, 0.5)"
                }
              }}
            >
              {stepButtonText}
            </Button>
          )}
          {stepButtonFunction && stepButtonText && (
            <Button variant="outlined" disabled={!currentStep} onClick={stepButtonFunction}>
              {stepButtonText}
            </Button>
          )}
        </WizardStepButtonContainer>
        <Grid item xs={12}>
          {statusIconOption === "notstarted" && <PanoramaFishEyeIcon style={{ color: "black" }} />}
          {statusIconOption === "inprogress" && <PendingIcon style={{ color: "blue" }} />}
          {statusIconOption === "complete" && <CheckCircleIcon style={{ color: "green" }} />}
          {statusIconOption === "error" && <ErrorIcon style={{ color: "red" }} />}
        </Grid>
      </Grid>
    </WizardStepContainer>
  );
};
