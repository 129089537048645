import React, { useEffect, useState } from "react";
import SpeakingOpportunity from "../Domain/SpeakingOpportunity";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, TextField, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { sendSpeakingOpportunityEmail } from "../Clients";

const OuterDiv = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "1em",
  },
  [theme.breakpoints.up("md")]: {
    padding: "5em",
  },
  paddingLeft: "10em",
  paddingRight: "10em",
}));

const AlignLeft = styled(Box)({
  textAlign: "left",
});

const ExtraLongInputBox = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "30em",
  },
  [theme.breakpoints.down("md")]: {
    width: "20em",
  },
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
}));

const MediumLongInputBox = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
  [theme.breakpoints.up("sm")]: {
    width: "20em",
  },
}));

const ExtraExtraLongInputBox = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "43em",
  },
  [theme.breakpoints.down("md")]: {
    width: "30em",
  },
  [theme.breakpoints.down("sm")]: {
    width: "15em",
  },
}));

const AddTopPadding = styled(Box)({
  paddingTop: "1em",
});

const TopPadding = styled(Box)({
  paddingTop: "1em",
});

const DonateText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25em",
  },
  fontFamily: "Roboto",
  fontWeight: 400,
}));

const CenteredText = styled(Box)({
  paddingTop: ".75em",
  textAlign: "center",
  paddingBottom: "1em",
});

export interface ISpeakingOpportunitiesProps {}

const SpeakingOpportunities: React.FC<ISpeakingOpportunitiesProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [isDateValid, setIsDateValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [rangeChecked, setRangeChecked] = useState(false);
  const [fromDateLabel, setFromDateLabel] = useState("Date of requested Speaking Engagement");

  const [speakingRequest, setSpeakingRequest] = useState(new SpeakingOpportunity());

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      speakingRequest.details &&
      speakingRequest.organizationName &&
      speakingRequest.firstName &&
      speakingRequest.lastName &&
      speakingRequest.emailAddress &&
      isDateValid &&
      checkEmailIsValid(speakingRequest.emailAddress) &&
      !emailError
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [speakingRequest, emailError, rangeChecked, isDateValid]);

  const inputChange = (e: any) => {
    setSingleFieldOnState(e.target.name, e.target.value);
  };

  const inputChangeDateChange = (e: any) => {
    var thisDate = new Date(e.target.value.replaceAll("-", "/"));
    setSingleFieldOnState(e.target.name, thisDate);
  };

  function setSingleFieldOnState(name: string, value: any) {
    setSpeakingRequest({
      ...speakingRequest,
      [name]: value,
    } as SpeakingOpportunity);
  }

  function setErrorIfNeeded() {
    if (!checkEmailIsValid(speakingRequest.emailAddress)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  function checkEmailIsValid(email: string) {
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  function checkDateIsValid(rangeChecked: boolean) {
    if (rangeChecked && speakingRequest.fromDate && speakingRequest.toDate) {
      setSingleFieldOnState(
        "dateRangeString",
        speakingRequest.fromDate.toLocaleDateString("en-US") + " - " + speakingRequest.toDate.toLocaleDateString("en-US"),
      );
      setIsDateValid(true);
    } else if (!rangeChecked && speakingRequest.fromDate) {
      setSingleFieldOnState("dateRangeString", speakingRequest.fromDate.toLocaleDateString("en-US"));
      setIsDateValid(true);
    } else {
      setSingleFieldOnState("dateRangeString", "");
      setIsDateValid(false);
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    sendSpeakingOpportunityEmail(speakingRequest);
    setLoading(false);
    setSubmitSuccessful(true);
  };

  const handleRangeChecked = () => {
    setFromDateLabel(!rangeChecked ? "From" : "Date of requested Speaking Engagement");
    setRangeChecked(!rangeChecked);
    checkDateIsValid(!rangeChecked);
  };

  return (
    <div>
      <h1>Speaking Opportunity</h1>
      {submitSuccessful && <h3>Your request has been submitted!</h3>}
      {loading && <CircularProgress />}
      {!submitSuccessful && !loading && (
        <OuterDiv>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <CenteredText>
                    <DonateText>
                      We would love to visit your organization to tell members about the program and how they can provide support for our critical
                      mission! Please help us connect by providing the information below. A representative will reach out to talk through the details.
                      Thank you in advance for your generosity and caring.
                    </DonateText>
                  </CenteredText>
                </Grid>
              </Grid>
            </Grid>
            <AlignLeft>
              <Grid container spacing={3}>
                <Grid item>
                  <FormControl>
                    <ExtraLongInputBox
                      name="firstName"
                      id="firstName"
                      value={speakingRequest.firstName}
                      onChange={inputChange}
                      variant="outlined"
                      label="First Name"
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <ExtraLongInputBox
                      name="lastName"
                      id="lastName"
                      value={speakingRequest.lastName}
                      onChange={inputChange}
                      variant="outlined"
                      label="Last Name"
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AlignLeft>
            <AlignLeft>
              <TopPadding>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl>
                      <ExtraLongInputBox
                        name="organizationName"
                        id="organizationName"
                        value={speakingRequest.organizationName}
                        onChange={inputChange}
                        variant="outlined"
                        label="Organization Name"
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <TextField
                        id="emailAddress"
                        name="emailAddress"
                        value={speakingRequest.emailAddress}
                        error={emailError}
                        label="Email Address"
                        helperText="Must be a valid email address"
                        onChange={inputChange}
                        variant="outlined"
                        required
                        style={{ width: "100%" }}
                        onBlur={() => {
                          setErrorIfNeeded();
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </TopPadding>
              <AddTopPadding>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl>
                      <MediumLongInputBox
                        type="date"
                        name="fromDate"
                        id="fromDate"
                        value={speakingRequest.fromDate ? speakingRequest.fromDate.toLocaleDateString("en-CA") : ""}
                        onChange={inputChangeDateChange}
                        variant="outlined"
                        label={fromDateLabel}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        onBlur={() => {
                          checkDateIsValid(rangeChecked);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {rangeChecked && (
                    <Grid item>
                      <FormControl>
                        <MediumLongInputBox
                          type="date"
                          name="toDate"
                          id="toDate"
                          value={speakingRequest.toDate ? speakingRequest.toDate.toLocaleDateString("en-CA") : ""}
                          onChange={inputChangeDateChange}
                          variant="outlined"
                          label="To"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          onBlur={() => {
                            checkDateIsValid(rangeChecked);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item>
                    <FormControl>
                      <FormControlLabel
                        control={<Checkbox checked={rangeChecked} onChange={handleRangeChecked} name="rangeChecked" color="primary" size="small" />}
                        label={
                          <Typography variant="body2" color="textSecondary">
                            Use Range Instead?
                          </Typography>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <AddTopPadding>
                    <ExtraExtraLongInputBox
                      id="details"
                      name="details"
                      multiline
                      rows={4}
                      value={speakingRequest.details}
                      onChange={inputChange}
                      variant="outlined"
                      label="Details of the request for a speaker"
                      required
                    />
                  </AddTopPadding>
                </Grid>
              </AddTopPadding>
              <AddTopPadding>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!allowSubmit}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </AddTopPadding>
            </AlignLeft>
          </Grid>
        </OuterDiv>
      )}
    </div>
  );
};

export default SpeakingOpportunities;