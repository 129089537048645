export function countUnique(items: any[], fieldToCheck: string): number {
    return items
        .map(item => item[fieldToCheck])
        .filter((value, index, self) => self.indexOf(value) === index)
        .length;
}

export function sumField(items: any[], fieldToSum: string): number {
    return items.reduce((acc, item) => {
        const value = item[fieldToSum];
        return acc + (typeof value === 'number' ? value : 0);
    }, 0)
}

export function getUniqueList(items: any[], fieldToDistinct: string): any[] {
    return items
        .map(item => item[fieldToDistinct])
        .filter((value, index, self) => self.indexOf(value) === index);
}