import { ApplicationStatus } from "../Domain/ApplicationStatus";
import AssistanceRequest from "../Domain/AssistanceRequest";
import { DonationAssistanceMonth } from "../Domain/Donation";

export function allAssistanceTotalsForDonationAsssistanceMonths(donationAssistanceMonths: DonationAssistanceMonth[], assistanceRequests: AssistanceRequest[]): DonationAssistanceMonth[] {
    donationAssistanceMonths.forEach(donationAssistanceMonth => {
        donationAssistanceMonth.assistanceSent = getMonthTotalRequests(donationAssistanceMonth.monthNumber, donationAssistanceMonth.yearNumber, assistanceRequests);
    })
    return donationAssistanceMonths;
}

function getMonthTotalRequests(month: number, year: number, requests: AssistanceRequest[]): number {
    return requests
        .filter(r => r.status === ApplicationStatus.Completed)
        .filter(t => isDateInMonthAndYear(t.requestDate, month, year))
        .reduce((sum, p) => sum + p.amountRequested, 0) || 0;
}

function isDateInMonthAndYear(dateString: string, month: number, year: number): boolean {
    // Parse the date string into a Date object
    const date = new Date(dateString);
    
    // Get the month and year from the Date object
    const dateMonth = date.getMonth() + 1; // getMonth() returns 0-11, so add 1
    const dateYear = date.getFullYear();
    
    // Check if the month and year match
    return dateMonth === month && dateYear === year;
}