import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';

const MainBody = styled('div')({
  display: "flex",
  justifyContent: "flex-start",
  alignContent: "flex-start",
  textAlign: "left",
  padding: "2em"
});

const Body = styled('div')({
  '&[data-custom-class="body"], &[data-custom-class="body"] *': {
    background: 'transparent !important',
  },
});

const Title = styled('span')({
  '&[data-custom-class="title"], &[data-custom-class="title"] *': {
    fontFamily: 'Arial !important',
    fontSize: '26px !important',
    color: '#000000 !important',
  },
});

const Subtitle = styled('span')({
  '&[data-custom-class="subtitle"], &[data-custom-class="subtitle"] *': {
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    color: '#595959 !important',
  },
});

const Heading1 = styled('span')({
  '&[data-custom-class="heading_1"], &[data-custom-class="heading_1"] *': {
    fontFamily: 'Arial !important',
    fontSize: '19px !important',
    color: '#000000 !important',
  },
});

const Heading2 = styled('span')({
  '&[data-custom-class="heading_2"], &[data-custom-class="heading_2"] *': {
    fontFamily: 'Arial !important',
    fontSize: '17px !important',
    color: '#000000 !important',
  },
});

const BodyText = styled('span')({
  '&[data-custom-class="body_text"], &[data-custom-class="body_text"] *': {
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    color: '#595959 !important',
  },
});

const Link = styled('a')({
  '&[data-custom-class="link"], &[data-custom-class="link"] *': {
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    color: '#3030F1 !important',
    wordBreak: 'break-word !important',
  },
});

const Ul = styled('ul')({
  listStyleType: 'square',
  '& > li > ul': {
    listStyleType: 'circle',
  },
  '& > li > ul > li > ul': {
    listStyleType: 'square',
  },
});

const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainBody>
      <span
        style={{
          display: 'block',
          margin: '0 auto 3.125rem',
          width: '11.125rem',
          height: '2.375rem'
        }}
      ></span>
      <Body data-custom-class="body">
        <div>
          <strong>
            <Title data-custom-class="title">
              PRIVACY POLICY
            </Title>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
          <span style={{ color: 'rgb(127, 127, 127)' }}>
            <strong>
              <Subtitle data-custom-class="subtitle">
                Last updated <span>July 02, 2024</span>
              </Subtitle>
            </strong>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ color: 'rgb(127, 127, 127)' }}>
            <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
              <BodyText data-custom-class="body_text">
                This privacy notice for <span>Be The Change Gulf Coast</span> ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:
              </BodyText>
            </span>
          </span>
        </div>
        <Ul>
          <li data-custom-class="body_text" style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
              Visit our website at <Link href="https://bethechangegulfcoast.com" target="_blank" rel="noreferrer" data-custom-class="link">https://bethechangegulfcoast.com</Link>, or any website of ours that links to this privacy notice
            </span>
          </li>
        </Ul>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px', color: 'rgb(127, 127, 127)' }}>
            <BodyText data-custom-class="body_text">
              <strong>Questions or concerns? </strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:support@bethechangegulfcoast.com">support@bethechangegulfcoast.com</a>.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <strong>
            <span style={{ fontSize: '15px' }}>
              <Heading1 data-custom-class="heading_1">SUMMARY OF KEY POINTS</Heading1>
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our </em></strong>
            </BodyText>
          </span>
          <Link data-custom-class="link" href="#toc">
            <span style={{ color: 'rgb(0, 58, 250)', fontSize: '15px' }}>
              <BodyText data-custom-class="body_text"><strong><em>table of contents</em></strong></BodyText>
            </span>
          </Link>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong><em> below to find the section you are looking for.</em></strong>
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <Link data-custom-class="link" href="#personalinfo">personal information you disclose to us</Link>.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <Link data-custom-class="link" href="#infouse">how we process your information</Link>.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Learn more about <Link data-custom-class="link" href="#whoshare">when and with whom we share your personal information</Link>.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <Link data-custom-class="link" href="#privacyrights">your privacy rights</Link>.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <BodyText data-custom-class="body_text">
              <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by filling out our data subject request form available here: <Link data-custom-class="link" href="https://bethechangegulfcoast.com/request-form">https://bethechangegulfcoast.com/request-form</Link>. We will consider and act upon any request in accordance with applicable data protection laws.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <strong>
            <span style={{ fontSize: '15px' }}>
              <Heading1 data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</Heading1>
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <strong>
            <span style={{ fontSize: '15px' }}>
              <Heading2 data-custom-class="heading_2">Personal information you disclose to us</Heading2>
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px', color: 'rgb(127, 127, 127)' }}>
            <BodyText data-custom-class="body_text">
              <strong>In Short:</strong> We collect personal information that you provide to us.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px', color: 'rgb(127, 127, 127)' }}>
            <BodyText data-custom-class="body_text">
              We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px', color: 'rgb(127, 127, 127)' }}>
            <BodyText data-custom-class="body_text">
              <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <Ul>
            <li style={{ fontSize: '15px', color: 'rgb(127, 127, 127)', lineHeight: '1.5' }}>
              <BodyText data-custom-class="body_text">names</BodyText>
            </li>
            <li style={{ fontSize: '15px', color: 'rgb(127, 127, 127)', lineHeight: '1.5' }}>
              <BodyText data-custom-class="body_text">phone numbers</BodyText>
            </li>
            <li style={{ fontSize: '15px', color: 'rgb(127, 127, 127)', lineHeight: '1.5' }}>
              <BodyText data-custom-class="body_text">email addresses</BodyText>
            </li>
            <li style={{ fontSize: '15px', color: 'rgb(127, 127, 127)', lineHeight: '1.5' }}>
              <BodyText data-custom-class="body_text">mailing addresses</BodyText>
            </li>
            <li style={{ fontSize: '15px', color: 'rgb(127, 127, 127)', lineHeight: '1.5' }}>
              <BodyText data-custom-class="body_text">job titles</BodyText>
            </li>
          </Ul>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px', color: 'rgb(127, 127, 127)' }}>
            <BodyText data-custom-class="body_text">
              <strong>Sensitive Information.</strong> We do not process sensitive information.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px', color: 'rgb(127, 127, 127)' }}>
            <BodyText data-custom-class="body_text">
              <strong>Payment Data.</strong> We do not collect or store payment information.
            </BodyText>
          </span>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px', color: 'rgb(127, 127, 127)' }}>
            <BodyText data-custom-class="body_text">
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </BodyText>
          </span>
        </div>
      </Body>
    </MainBody>
  );
};

export default PrivacyPolicy;