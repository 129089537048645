import React, { useState } from "react";
import { Modal, Button, Box, Typography, Grid, styled } from '@mui/material';
import { DateInput } from "../shared/DateInput";
import { adjustMonths, dateIsBetween, formatDate } from "../../Helpers/DateHelper";
import { ExportType } from "../shared/ExportType";
import ReportElement from "../shared/ReportElement";
import { ButterTransaction } from "../../Domain/ButterInfo";

interface IButterTransactionReport{
    butterTransactions?: ButterTransaction[];
    reportName?: string;
    byDateRange?: boolean;
}

const MainSpan = styled('span')(() => ({
    fontSize: "1em",
    height: "100%",
  }));

const ReportLinkDiv = styled('div')(({ theme }) => ({
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline",
      color: theme.palette.primary.main, // Change this to the color you want on hover
    }
  }));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ButterTransactionReport: React.FC<IButterTransactionReport> = ({butterTransactions, reportName, byDateRange}) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const handleOpen = () => setShowModal(true);
    const handleClose = () => {reset(); setShowModal(false);}

    const currentDate = new Date();
    const threeMonthsBack = new Date();
    threeMonthsBack.setMonth(adjustMonths(currentDate.getMonth(), -3));

    const [startRange, setStartRange] = useState<string>(formatDate(threeMonthsBack));
    const [endRange, setEndRange] = useState<string>(formatDate(currentDate));

    const onStartDateChange = (newDate: Date | undefined) => {
        if(newDate){
            setStartRange(newDate.toDateString());
        }
    };

    const onEndDateChange = (newDate: Date | undefined) => {
        if(newDate){
            setEndRange(newDate.toDateString());
        }
    };

    const reset = () => {
        setStartRange(formatDate(threeMonthsBack));
        setEndRange(formatDate(currentDate));
    };

    const getData = ():ButterTransaction[] => {
        var filteredResults = [] as ButterTransaction[];
        if (byDateRange && butterTransactions) {
            filteredResults = butterTransactions.filter(a => dateIsBetween(a.transacted_at, startRange, endRange));
        }
        
        if (filteredResults) {
            return filteredResults;
        }
        else {
            return [];
        }
    }

    return (
        <MainSpan>
            <div>
                <ReportLinkDiv onClick={() => handleOpen()}>
                    {reportName}
                </ReportLinkDiv>
            </div>
            <Modal
                open={showModal}
                onClose={handleClose}
                aria-labelledby="export-modal"
                aria-describedby="Export the assistance requests"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: 'blur(3px)',
                }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Export Butter Transactions
                    </Typography>
                    {byDateRange && (
                        <>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Select the date range
                            </Typography>
                            <div style={{padding:".5em"}}><DateInput label="Start Date" size="short" value={startRange} onChange={onStartDateChange}/></div>
                            <div style={{padding:".5em"}}><DateInput label="End Date" size="short" value={endRange} onChange={onEndDateChange}/></div>
                        </>
                    )}                    
                    <Grid container spacing={1}>
                        <Grid item>
                            <ReportElement getRecords={getData} label="Export" exportType={ExportType.excel} clickableElement="button" fileNameForDownload="ButterTransactionReport" postDownloadTrigger={handleClose}/>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClose} variant="contained">
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </MainSpan>
    );
};

export default ButterTransactionReport;