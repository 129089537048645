import FileUpload from "../shared/FileUpload";

interface IUploadEvidence {
    showUploadMessage: boolean;
    fileList: File[] | undefined;
    updateFileList: (newFileList: File[] | undefined) => void;
}

export const UploadEvidence = ({showUploadMessage, fileList, updateFileList}: IUploadEvidence) =>{
    const message = "For requests over $100.00 please attach evidence of cost (screen shots from retail site, picture from store, bills, receipts, etc). Also, any requests over $100.00 must be reviewed by the board of Be The Change Gulf Coast."
    const deleteFile = (file: File) => {
        var files = fileList;
        var index = files?.indexOf(file) ?? 0;
        files?.splice(index, 1);
        updateFileList(files);
    };

    const fileUploadChange = (newFiles: File[]) => {
        let currentFileList = fileList;
        if (currentFileList) {
            currentFileList = currentFileList.concat(newFiles);
        } else {
            currentFileList = newFiles;
        }
        updateFileList(currentFileList!);
    };

    return (
        <>
            <FileUpload 
                fileList={fileList ?? []}
                buttonText="Upload"
                onFilesAdded={fileUploadChange}
                onFileDelete={deleteFile}
                allowDelete={true}
                uploadErrorOrWarningMessage={showUploadMessage ? message : ""}
                uploadInstructionMessage="Upload any documentation to support your request (reciepts, bills, etc)."
            />
        </>
    );
}