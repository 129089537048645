import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IActionDrawer {
    drawerContent?: any;
    label?: string;
    direction?: "top" | "bottom" | "left" | "right"
}

export const ActionDrawer: React.FC<IActionDrawer> = ({drawerContent, direction, label}) => {
  direction = direction ?? "right";
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(open);
    };

  const DrawerContents = () => (
    <Box
      sx={{ width: "50em" }}
      role="presentation"
    >
      <div style={{padding:"1.5em"}}>{drawerContent}</div>
    </Box>
  );

  return (
    <div>      
      <Button onClick={toggleDrawer(true)}>{label}</Button>
      <Drawer
        anchor={direction}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Grid container style={{padding:"3em"}}>
          <Grid item xs={12} style={{textAlign:"right"}}><CloseIcon onClick={toggleDrawer(false)}/></Grid>
          <Grid item xs={12}>
            {DrawerContents()}
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
}