import { Grid } from "@mui/material";

export interface ILabelAndTextDisplayProps {
    label?: string | undefined;
    value?: React.ReactElement;
    hasColonSeparator?: boolean | undefined;
}

const LabelAndAnyDisplay: React.FC<ILabelAndTextDisplayProps> = ({label, value, hasColonSeparator}) => {
    hasColonSeparator = hasColonSeparator === false || !label ? false : true;
    return (
        <Grid item xs={12} style={{paddingBottom:"1em"}}>
            <Grid container wrap="nowrap" style={{fontSize:"1.5vh"}} spacing={1}>
                {label && (<Grid item style={{fontWeight:"bold"}}>{label}{hasColonSeparator ? ":" : ""}</Grid>)}
                {value && (
                    <Grid item>{value}</Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default LabelAndAnyDisplay;
