import React, { useState, useEffect } from "react";
import {ContactUsEmail} from "../Domain/ConteactUsEmail";
import { Button, Grid, TextField } from "@mui/material";
import { sendContactUsEmail } from "../Clients";

const Contact: React.FC = () => {
  const [email, setEmail] = useState(new ContactUsEmail());
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const inputChange = (e: any) => {
    setSingleFieldOnState(e.target.name, e.target.value);
    checkIsFormComplete();
  };

  function setSingleFieldOnState(name: string, value: any) {
    setEmail({
      ...email,
      [name]: value,
    } as ContactUsEmail);
  }

  function checkIsFormComplete() {
    if (email.from_email_address !== "" && checkEmailIsValid(email.from_email_address) && email.message !== "" && email.from_name !== "") {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }

  function checkEmailIsValid(email: string) {
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  function handleSubmit() {
    sendContactUsEmail(email);
    setSubmitSuccessful(true);
  }

  function setErrorIfNeeded() {
    if (!checkEmailIsValid(email.from_email_address)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  return (
    <React.Fragment>
      <h1>Contact Us</h1>
      <Grid container justifyContent="center" style={{ paddingBottom: "1em" }}>
        {submitSuccessful && <h3>Your message has been sent!</h3>}
        {!submitSuccessful && (
          <Grid item xs={6}>
            <Grid container justifyContent="flex-start" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="from_email_address"
                  name="from_email_address"
                  value={email.from_email_address}
                  error={emailError}
                  label="Email Address"
                  helperText="Must be a valid email address"
                  onChange={inputChange}
                  variant="outlined"
                  required={true}
                  style={{ width: "100%" }}
                  onBlur={() => {
                    setErrorIfNeeded();
                    checkIsFormComplete();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="from_name"
                  name="from_name"
                  value={email.from_name}
                  label="Name"
                  onChange={inputChange}
                  variant="outlined"
                  required={true}
                  style={{ width: "100%" }}
                  onBlur={() => {
                    checkIsFormComplete();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="message"
                  name="message"
                  multiline
                  rows={6}
                  value={email.message}
                  onChange={inputChange}
                  variant="outlined"
                  label="Message"
                  required={true}
                  style={{ width: "100%" }}
                  onBlur={checkIsFormComplete}
                />
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={3}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isFormComplete}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default Contact;
