import React from 'react';
import { Member } from '../../Domain/BoardMember';
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IBoardMemberCardContents {
    member: Member;
    moreClick: () => void;
}

const MemberPicture = styled('img')(() => ({
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
}));

const StyledButton = styled(Button)(() => ({
    padding: '.5em .75em',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#0056b3',
    },
}));

const BoardMemberCardContents: React.FC<IBoardMemberCardContents> = ({ member, moreClick }) => {
    const getShortBio = (bio: string, wordCount: number) => {
        const words = bio.split(' ');
        if (words.length <= wordCount) return bio;
        return words.slice(0, wordCount).join(' ') + '...';
    };

    const shortBio = getShortBio(member.bio[0], 15);

    return (
        <Grid container direction="column" justifyItems="flex-end" alignItems="center">
            <Grid item><MemberPicture src={member.picture} alt={member.name} /></Grid>
            <Grid item><h2>{member.name}</h2></Grid>
            <Grid item><h3>{member.title}</h3></Grid>
            <Grid item><p style={{ minHeight: "4vh" }}>{shortBio}</p></Grid>
            {shortBio !== "" && (
                <Grid item>
                    <StyledButton onClick={moreClick}>
                        Learn More
                    </StyledButton>
                </Grid>
            )}
        </Grid>
    );
};

export default BoardMemberCardContents;