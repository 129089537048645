import { Grid } from "@mui/material";

export interface ILabelAndTextDisplayProps {
    label?: string | undefined;
    text?: (string | undefined)[] | string | undefined;
    hasColonSeparator?: boolean | undefined;
}

const LabelAndTextDisplay: React.FC<ILabelAndTextDisplayProps> = ({label, text, hasColonSeparator}) => {
    hasColonSeparator = hasColonSeparator === false || !label ? false : true;
    return (
        <Grid item xs={12} style={{paddingBottom:"1em"}}>
            <Grid container wrap="nowrap" style={{fontSize:"1.5vh"}} spacing={1}>
                {label && (<Grid item style={{fontWeight:"bold"}}>{label}{hasColonSeparator ? ":" : ""}</Grid>)}
                {text && (
                    <Grid item>
                        {!Array.isArray(text) && text}
                        {Array.isArray(text) && text?.map((t, i) => 
                            <Grid item xs={12} key={"text" + i}>{t}</Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default LabelAndTextDisplay;
