import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { HeadCell } from "./HeadCell";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getNumericAsCurrencyString } from "../../../Helpers/StringHelpers";
import { styled } from "@mui/material/styles";

const ValuesTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: 13,
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: 10,
  },
}));

export interface IEnhancedTableRowProps<T> {
  index: number;
  labelId: string;
  row: any;
  headCells: HeadCell<T>[];
  expandSection?: (row: any) => any;
  iconOnFirstColumn?: (row: any) => any;
  rowClickAction?: (row: any) => void;
}

export default function EnhancedTableRow<T>(props: IEnhancedTableRowProps<T>) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [chevronRight, setChevronRight] = React.useState<boolean>(true);
  const [contentVisible, setContentVisible] = React.useState<boolean>(false);

  const openExpandSection = (index: number) => {
    if (props.expandSection) {
      if (expanded === "expandedSection" + index) {
        setTimeout(() => setContentVisible(false), 800); // Match this with the transition duration
        setExpanded(false);
      } else {
        setExpanded("expandedSection" + index);
        setTimeout(() => setContentVisible(true), 10); // Small delay to allow transition to start
      }
    }
  };

  const triggerRowClick = () => {
    if (props.rowClickAction) {
      props.rowClickAction(props.row);
    }
  };

  const getValueOfField = (row: T, head: HeadCell<T>): string => {
    if (head.customGetter !== undefined) {
      return head.customGetter(row)?.toString() ?? "";
    } else if (head.isDollarAmount) {
      return getNumericAsCurrencyString(props.row[head.id]);
    } else {
      return props.row[head.id];
    }
  };

  return (
    <React.Fragment key={"reactFragment_" + props.index}>
      <TableRow
        hover
        tabIndex={props.expandSection ? -1 : 0}
        key={props.labelId + "_key"}
        id={props.labelId}
        onClick={() => {
          openExpandSection(props.index);
          setChevronRight(!chevronRight);
          triggerRowClick();
        }}
      >
        {props.expandSection && (
          <TableCell style={{ width: "2%" }}>
            {chevronRight ? <ChevronRightIcon /> : <KeyboardArrowDownIcon />}
          </TableCell>
        )}
        {props.headCells.map((head, i) => (
          <ValuesTableCell key={"col" + head.id.toString() + props.index} align={"left"}>
            {i === 0 && props.iconOnFirstColumn && <>{props.iconOnFirstColumn!(props.row)}</>}
            {getValueOfField(props.row, head)}
          </ValuesTableCell>
        ))}
      </TableRow>
      {props.expandSection && (
        <TableRow key={"expandedKey" + props.index}>
          <TableCell colSpan={props.headCells.length + 1} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <div
              style={{
                maxHeight: expanded === "expandedSection" + props.index ? "1000px" : "0",
                opacity: expanded === "expandedSection" + props.index ? 1 : 0,
                overflow: "hidden",
                transition: "max-height 0.8s ease, opacity 0.8s ease",
              }}
            >
              <div style={{ padding: contentVisible ? "1em" : 0 }}>
                {contentVisible && props.expandSection && props.expandSection(props.row)}
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
