import React, { useState } from "react";
import { Grid, Table, TableBody, TableContainer, TablePagination, TextField } from "@mui/material";
import { Order } from "./Order";
import { HeadCell } from "./HeadCell";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableRow from "./EnhancedTableRow";
import { styled } from "@mui/material/styles";
import { stableSort, getComparator } from "./SortingFunctions";
import { TableTitle } from "./TableTitle";

export interface IEnhancedTableProps<T> {
  rows: any[];
  title?: string;
  headCells: HeadCell<T>[];
  initialOrderBy?: keyof T;
  expandSection?: (row: any) => any;
  iconOnFirstColumn?: (row: any) => any;
  hideHeaderRow?: boolean;
  enableFiltering?: boolean;
  enablePaging?: boolean;
  rowClickAction?: (row: any) => void;
}

const Root = styled('div')(() => ({
  width: "100%",
}));

const TableWrapper = styled(Table)(() => ({
  width: "100%",
  tableLayout: "auto",
}));

export default function EnhancedTable<T>(props: IEnhancedTableProps<T>) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(props.initialOrderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState('');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
    setPage(0);
  };

  const getFilteredRows = () => {
    return props.rows.filter(row => {
      return Object.keys(row).some(key =>
        String(row[key]).toLowerCase().includes(filterText.toLowerCase())
      );
    });
  };

  const getSortedList = () => {
    if(orderBy){
      return stableSort(getFilteredRows(), getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    else {      
      return getFilteredRows()
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
  };

  return (
    <Root>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {props.title && <TableTitle titleText={props.title}/>}
          {props.enableFiltering && (
            <TextField
              label="Filter"
              variant="outlined"
              fullWidth
              value={filterText}
              onChange={handleFilterChange}
              style={{ marginBottom: '10px' }}
            />
          )}
          {props.enablePaging && (
            <TablePagination
              component="div"
              count={getFilteredRows().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          <TableContainer>
            <TableWrapper size="small">
              {!props.hideHeaderRow && (
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={props.headCells} hasExpandedSection={!!props.expandSection} />
              )}
              <TableBody>
                {getSortedList().map((row, index) => {
                  return (
                    <EnhancedTableRow
                      key={`tableRowKey-${index}`}
                      index={index}
                      labelId={`tableRowLabelId-${index}`}
                      row={row}
                      headCells={props.headCells}
                      expandSection={props.expandSection}
                      iconOnFirstColumn={props.iconOnFirstColumn}
                      rowClickAction={props.rowClickAction}
                    />
                  );
                })}
              </TableBody>
            </TableWrapper>
          </TableContainer>
          {getSortedList().length === 0 && <div>No requests found</div>}
          {props.enablePaging && (
            <TablePagination
              component="div"
              count={getFilteredRows().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>
    </Root>
  );
}