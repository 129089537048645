import beeButton from "../Images/bethechange--bee in honeycomb-04.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { Grid, styled } from "@mui/material";
import { RouteConstants } from "../Constants/RouteConstants";

const MainContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textAlign: "center",
  paddingTop: "1em",
  paddingLeft: "1em",
  paddingRight: "1em",
}));

const FootingText = styled(Grid)(({ theme }) => ({
  fontSize: theme.breakpoints.up("md") ? "1.2em" : ".75em",
  fontWeight: 500,
  color: theme.palette.primary.contrastText,
}));

const BottomFootingGridContainer = styled(Grid)(({ theme }) => ({
  fontSize: theme.breakpoints.up("md") ? "1.2em" : ".75em",
  fontWeight: 500,
  color: theme.palette.primary.contrastText,
}));

const TopOfFoot = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.breakpoints.up("md") ? "2em" : undefined,
}));

const BottomOfFoot = styled(Grid)({
  paddingTop: "2em",
  paddingBottom: "1em",
});

const LogoSmall = styled('img')(() => ({
  width: "100%",
  height: "auto",
}));

const LogoContainer = styled(Grid)({
  textAlign: "right",
  paddingRight: "1vw",
});

const CopyRightContainer = styled(Grid)(({ theme }) => ({
  justifyContent: theme.breakpoints.down("md") ? "flex-start" : "flex-end",
}));

const CopyRightText = styled(Grid)(({ theme }) => ({
  textAlign: "left",
  fontWeight: 300,
  fontSize: theme.breakpoints.down("md") ? "1em" : "1em"
}));

const LeftAlign = styled(Grid)({
  textAlign: "left",
});

export default function Footer() {
  return (
    <MainContainer container justifyContent="space-between">
      <TopOfFoot item xs={12}>
        <FootingText container justifyContent="space-evenly">
          <Grid item xs={3} md={1}>
            <a rel="noreferrer" href="https://www.guidestar.org/profile/shared/066278db-431a-4d98-ae49-7d4f87036093" target="_blank">
              <img style={{ width: "100%" }} alt="CandidSeal" src="https://widgets.guidestar.org/TransparencySeal/9917076" />
            </a>
          </Grid>
          <Grid item xs={4}>
            <LeftAlign container direction="column" alignItems="flex-start">
              <Grid item>Be the Change Gulf Coast</Grid>
              <Grid item>(251) 455-3315</Grid>
              <Grid item>312 Schillinger Road South</Grid>
              <Grid item>Suite T, Box 175</Grid>
              <Grid item>Mobile, AL 36608</Grid>
            </LeftAlign>
          </Grid>
          <Grid item xs={3} md={6}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
              <Grid item>
                <Link to={RouteConstants.Contact} style={{ textDecoration: "none", color: "inherit" }}>Contact</Link>
              </Grid>
              <Grid item>
                <Link to={RouteConstants.NewsAndPress} style={{ textDecoration: "none", color: "inherit" }}>News & Press</Link>
              </Grid>
              <Grid item>
                <Link to={RouteConstants.PrivacyPolicy} style={{ textDecoration: "none", color: "inherit" }}>Privacy Policy</Link>
              </Grid>
              <Grid item>
                <Link to={RouteConstants.SiteTerms} style={{ textDecoration: "none", color: "inherit" }}>Site Terms</Link>
              </Grid>
              <Grid item>
                <Link to={RouteConstants.StateDisclosures} style={{ textDecoration: "none", color: "inherit" }}>State Disclosures</Link>
              </Grid>
            </Grid>
          </Grid>
        </FootingText>
      </TopOfFoot>
      <BottomOfFoot item xs={12}>
        <BottomFootingGridContainer container justifyContent="space-between" alignContent="center" alignItems="center">
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={2} md={1}>
                <a href="https://www.facebook.com/Be-The-Change-Gulf-Coast-100205021739820" style={{ color: "inherit" }}>
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </Grid>
              <Grid item xs={2} md={1}>
                <a href="https://twitter.com/bethechange_gc" style={{ color: "inherit" }}>
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </Grid>
              <Grid item xs={2} md={1}>
                <a href="https://www.instagram.com/bethechangegulfcoast/" style={{ color: "inherit" }}>
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </Grid>
              <Grid item xs={2} md={1}>
                <a href="https://www.youtube.com/channel/UCG_L-nic69rTkS_-5ZKGZnA" style={{ color: "inherit" }}>
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <CopyRightContainer container>
              <LogoContainer item xs={1} md={2}>
                <LogoSmall src={beeButton} alt="Donate Bee" />
              </LogoContainer>
              <CopyRightText item xs={11} md={10} alignSelf="center">(c) Be The Change NonProfit</CopyRightText>
            </CopyRightContainer>
          </Grid>
        </BottomFootingGridContainer>
      </BottomOfFoot>
    </MainContainer>
  );
}