import { ReactElement, useEffect, useState } from "react";
import { Button } from '@mui/material';
import { downloadExcel } from "../../Helpers/FileHelper";
import { ExportType } from "./ExportType";

interface IReportElement<T>{
    label?: string;
    clickableElement?: ReactElement | "link" | "button";
    exportType?: ExportType;
    fileNameForDownload?: string;
    getRecords: () => T[];
    postDownloadTrigger: () => void;
}

export default function ReportElement<T>({getRecords, label, clickableElement, exportType, fileNameForDownload, postDownloadTrigger }: IReportElement<T>) {
    
    const [element, setElement] = useState<ReactElement>();

    useEffect(() => {
        setElement(clickableElement === "link" ? (<span>{label}</span>) : clickableElement === "button" || !clickableElement ? (<Button variant="contained">{label}</Button>) : clickableElement);
    }, [clickableElement, label]);

    const handleExport = async () => {
        if (exportType === ExportType.excel) {
            downloadExcel(getRecords(), fileNameForDownload + ".xlsx");
            postDownloadTrigger();
        }
    };

    return (
        <span onClick={handleExport}>{element}</span>
    );
};
