import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { DonationAssistanceMonth } from '../../Domain/Donation';
import { getNumericAsCurrencyString } from '../../Helpers/StringHelpers';
import { Grid, Paper, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CustomCircularProgress from '../shared/CustomCircularProgress';
import { useEffect, useState } from 'react';

interface IDonationBarChart {
  dataset: DonationAssistanceMonth[];
  toggleTriggered?: (yearDefinition: "YTD" | "ROLL") => void;
  isLoading: boolean;
}

const ChartContainerDiv = styled(Grid)(() => ({
  width: '100%',
  height: 'auto',
  paddingLeft: "1em"
}));

const DonationBarChart: React.FC<IDonationBarChart> = ({ dataset, isLoading, toggleTriggered }) => {
  const chartSetting = {
    height: 300,
  };

  const valueFormatter = (value: number | null) => getNumericAsCurrencyString(value ?? 0);
  const [yearDefiniation, setYearDefiniation] = useState<"YTD" | "ROLL">("YTD");
  const [yearDescriptionForLabel, setYearDescriptionForLabel] = useState<string>("");

  useEffect(() => {
    if (dataset && dataset[0] && dataset[0].yearNumber && dataset[0].yearNumber === dataset?.[dataset.length - 1].yearNumber) {
      setYearDescriptionForLabel(`Jan - Dec of ${dataset[0]?.yearNumber}`);
    } else if (dataset && dataset[0] && dataset[0].yearNumber) {
      var startYear = dataset[0].yearNumber;
      var startMonth = dataset[0].monthName;
      var endYear = dataset[dataset.length - 1].yearNumber;
      var endMonth = dataset[dataset.length - 1].monthName
      setYearDescriptionForLabel(`${startMonth} ${startYear} - ${endMonth} ${endYear}`);
    }
  }, [dataset])

  const handleToggle = (event: any) => {
    var toggle = event.target.value;
    if (toggleTriggered) {
      toggleTriggered(toggle);
    }
    setYearDefiniation(toggle);
  }

  return (
    <>
      <Paper>
        {isLoading && (<CustomCircularProgress message="Loading donation chart" />)}
        {!isLoading && (
          <>
            <Grid item xs={12} style={{ paddingTop: "1em", fontWeight: "bold" }}>Donations vs Assistance</Grid>
            <Grid item>{yearDescriptionForLabel}</Grid>
            <ChartContainerDiv>
              <BarChart
                dataset={dataset}
                xAxis={[{ scaleType: 'band', dataKey: 'monthName' }]}
                series={[
                  { dataKey: 'donationsRecieved', label: 'Donation', valueFormatter },
                  { dataKey: 'assistanceSent', label: 'Assistance', valueFormatter },
                ]}
                {...chartSetting}
              />
            </ChartContainerDiv>
            <Grid item>
              <ToggleButtonGroup
                value={yearDefiniation}
                exclusive
                onChange={handleToggle}
                aria-label="text alignment"
                style={{ paddingBottom: "1em" }}
              >
                <ToggleButton value="YTD" aria-label="left aligned">
                  Current Year To Date
                </ToggleButton>
                <ToggleButton value="ROLL" aria-label="centered">
                  Last 12 Months
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </>
        )}
      </Paper>
    </>
  );
};

export default DonationBarChart;
