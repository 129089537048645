import JSZip from "jszip";
import * as XLSX from 'xlsx';

export const ParseZipIntoListOfFiles = async (zip: File): Promise<File[]> => {
  const listOfFiles: File[] = [];
  const zipper = new JSZip();
  
  try {
    const contents = await zipper.loadAsync(zip);
    
    for (const fileName of Object.keys(contents.files)) {
      const fileBlob = await zipper.file(fileName)?.async('blob');
      if (fileBlob) {
        const file = new File([fileBlob], fileName, { lastModified: new Date().getTime() });
        listOfFiles.push(file);
      }
    }
  } catch (ex) {
    // Handle the exception if needed
  }

  return listOfFiles;
};


export const ZipUpFileList = async (files: File[]): Promise<File> => {
    var zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i]);
    }
    var zipContents = await zip.generateAsync({ type: "blob"});
    return zipContents as File;
  }

  
export const downloadExcel = (arrayData: any[], filename = 'report1.xlsx') => {
  // Generate workbook and add a sheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(arrayData);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write workbook and download
  XLSX.writeFile(wb, filename);
}