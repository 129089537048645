import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const ExtraExtraLongInputBox = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const MediumLongInputBox = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
  [theme.breakpoints.up("md")]: {
    width: "20vw",
  },
}));

const ShortInputBox = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.up("md")]: {
    width: "10vw",
  },
}));

interface IMultiLineTextInput {
    label: string;
    name?: string;
    value?: string;
    onChange?: (value: string) => void;
    size?: "wide" | "medium" | "short"
    required?: boolean;
    errorMessage?: string;
    showErrorMessage?: boolean;
}

export const MultiLineTextInput = ({label, value, name, onChange, size = "wide", required, errorMessage, showErrorMessage}: IMultiLineTextInput) => {

    const getInputBoxComponent = () => {
        switch (size) {
            case 'medium':
                return MediumLongInputBox;
            case 'wide':
                return ExtraExtraLongInputBox;
            case 'short':
                return ShortInputBox;
            default:
                return TextField;
        }
    };

    const InputBoxComponent = getInputBoxComponent();

    return (
        <InputBoxComponent
            id={name}
            name={name}
            value={value}
            placeholder={undefined}
            multiline={true}
            rows={4}
            onChange={(event: any) => {
                if(onChange){
                    onChange(event.target.value);
                }
            }}
            helperText={showErrorMessage ? errorMessage : ""}
            error={showErrorMessage}
            variant="outlined"
            label={label}
            required={required}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}