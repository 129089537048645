export const formatDate = (date: Date):string => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

export const adjustMonths = (currentMonthNumber: number, adjustMonthBy: number): number => {
    return (currentMonthNumber + adjustMonthBy + 12) % 12;
}

export const dateIsBetween = (dateToCheck: string, startDate: string, endDate: string):boolean => {
    // Parse the dates from the string inputs
    const checkDate = new Date(dateToCheck);
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Ensure the date ranges are valid
    if (start > end) {
        return false;
    }

    // Check if dateToCheck is between startDate and endDate (inclusive)
    return checkDate >= start && checkDate <= end;
}