import { Order } from "./Order";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  var compareb: any;
  var comparea: any;
  if (!isNaN(Number(b[orderBy])) && !isNaN(Number(a[orderBy]))) {
    compareb = Number(b[orderBy]);
    comparea = Number(a[orderBy]);
  } else if (!isNaN(Date.parse(b[orderBy] + "")) && !isNaN(Date.parse(a[orderBy] + ""))) {
    compareb = Date.parse(b[orderBy] + "");
    comparea = Date.parse(a[orderBy] + "");
  } else {
    compareb = b[orderBy];
    comparea = a[orderBy];
  }
  if (compareb < comparea) {
    return -1;
  }
  if (compareb > comparea) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
