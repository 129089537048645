import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import DonationBarChart from "./DonationBarChart";
import { DonationAssistanceMonth } from "../../Domain/Donation";
import NewItems from "./NewItems";
import { ApplicationStatus } from "../../Domain/ApplicationStatus";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import CustomCircularProgress from "../shared/CustomCircularProgress";
import AssistanceSummary from "./AssistanceSummary";
import DonationSummary from "./DonationSummary";
import { allTransactionTotalsToDonationAsssistanceMonths } from "../../Helpers/TransactionHelper";
import { allAssistanceTotalsForDonationAsssistanceMonths } from "../../Helpers/AssistanceRequestHelper";
import { getAllMonthsForAGivenYear, getAllMonthsForAYearFromStartDate } from "../../Helpers/DonationAssistanceMonthHelper";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { ButterCampaign, ButterPlan, ButterTransaction } from "../../Domain/ButterInfo";
import { UserProfileType } from "../../Domain/UserProfile";

interface IAdminDashboard {
    assistanceRequestList: AssistanceRequest[];
    allButterCampaigns: ButterCampaign[];
    butterTransactionList: ButterTransaction[];
    butterPlanList: ButterPlan[];
    userProfileList: UserProfileType[];
    isLoadingUserProfiles: boolean;
    isLoadingAssistanceRequests: boolean;
    isLoadingButterData: boolean;    
    onApplicationStatusUpdate: (requestId: string, status: ApplicationStatus, completionDate?: string, payoutNotes?: string) => Promise<void>;
    onProfileVerificationStatusUpdate: (userId: string, status: VerificationStatus) => Promise<void>;
    onDocumentationUpdated: (requestId: string, newS3Key: string) => Promise<void>;
}

const AdminDashboard: React.FC<IAdminDashboard> = ({assistanceRequestList, allButterCampaigns, butterTransactionList, butterPlanList, userProfileList, isLoadingUserProfiles, isLoadingAssistanceRequests, isLoadingButterData, onApplicationStatusUpdate, onProfileVerificationStatusUpdate, onDocumentationUpdated}) => {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const [chartDataSet, setChartDataSet] = useState<DonationAssistanceMonth[]>([]);
    const [newAssistanceRequests, setNewAssistanceRequests] = useState<AssistanceRequest[]>([]);
    const [newOrganizations, setNewOrganizations] = useState<UserProfileType[]>([]);
    

    useEffect(() => {
        if(assistanceRequestList.length > 0 && butterTransactionList.length > 0){
            var months = getAllMonthsForAGivenYear(currentYear);
            months = allTransactionTotalsToDonationAsssistanceMonths(months, butterTransactionList);
            months = allAssistanceTotalsForDonationAsssistanceMonths(months, assistanceRequestList);
            setChartDataSet(months);
            setNewAssistanceRequests(assistanceRequestList.filter(r => r.status === ApplicationStatus.New || r.status === ApplicationStatus.Processing));
            setNewOrganizations(userProfileList.filter(p => p.verificationStatus === VerificationStatus.New));
        }
    }, [assistanceRequestList, butterTransactionList, userProfileList, currentYear])

    const toggleTriggered = async (toggle: "YTD" | "ROLL") => {
        var months = [];

        if(toggle === "YTD"){
            months = getAllMonthsForAGivenYear(currentYear);
        }
        else {
            const previousDate = new Date(currentDate);
            previousDate.setFullYear(currentDate.getFullYear() - 1);
            previousDate.setMonth(currentDate.getMonth() + 1);

            months = getAllMonthsForAYearFromStartDate(previousDate.getMonth() + 1, previousDate.getFullYear());
        }
        months = allTransactionTotalsToDonationAsssistanceMonths(months, butterTransactionList);
        months = allAssistanceTotalsForDonationAsssistanceMonths(months, assistanceRequestList);
        setChartDataSet(months);
    }

    return (
        <div style={{ height: '100%' }}>
            {(isLoadingAssistanceRequests || isLoadingUserProfiles || isLoadingButterData) && (
                <CustomCircularProgress message="Loading dashboard" />
            )}
            {!isLoadingAssistanceRequests && !isLoadingUserProfiles && !isLoadingButterData && (
                <Grid item xs={12} style={{ height: '100%' }}>
                <Grid container justifyContent="center" alignContent="center">
                    <Grid item xs={12} style={{ paddingBottom: "1em" }}>
                        <Grid container justifyContent="space-between" alignContent="center" alignItems="flex-start" spacing={2}>
                            {(newAssistanceRequests.length > 0 || newOrganizations.length > 0) && (
                                <Grid item xs={12}>
                                    <NewItems
                                        newOrInProgressAssistanceRequests={newAssistanceRequests}
                                        newOrganizations={newOrganizations}
                                        isLoading={isLoadingAssistanceRequests || isLoadingUserProfiles}
                                        onAssistanceRequestStatusUpdate={onApplicationStatusUpdate}
                                        onOrganizationVerificationStatusUpdate={onProfileVerificationStatusUpdate}
                                        onDocumentationUpdated={onDocumentationUpdated}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <DonationBarChart dataset={chartDataSet} isLoading={isLoadingAssistanceRequests || isLoadingButterData} toggleTriggered={toggleTriggered} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignContent="center" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <AssistanceSummary assistanceRequests={assistanceRequestList} isLoading={isLoadingAssistanceRequests}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DonationSummary campaigns={allButterCampaigns} butterPlans={butterPlanList} isLoading={isLoadingButterData}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            )}
        </div>
    );
};

export default AdminDashboard;