import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../Authentication";
import { GetLoginWithRedirectParams } from "../../Constants/Auth0Constants";
import { RouteConstants } from "../../Constants/RouteConstants";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const PortalBanner:React.FC = () => {
  const userFromContext = useContext(AuthContext);
  const {loginWithRedirect} = useAuth0();
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.only("xs"));
  const navigate = useNavigate();
  
  const handleLogout = () => {
      userFromContext?.logOut?.(window.location.origin);
  }

  return (
    <>
      {userFromContext && userFromContext.accessToken && (
        <Grid item xs={12} style={{backgroundColor:"#83CC78", color:"white", padding:"1em", fontSize:"1.5vh"}}>
          <Grid container justifyContent="space-between" alignContent="center">
            <Grid item style={{paddingBottom: isXSmall ? "1em" : "none"}}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-start">
                    <Grid item onClick={() => navigate(RouteConstants.PortalRouter)} style={{cursor:"pointer"}}>{ !userFromContext?.isReadWriteAdministrator && !userFromContext.isReadOnlyAdministrator ? "Community Provider Portal" : "Admin Portal" }</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                {userFromContext && userFromContext.accessToken && (
                  <Grid item>
                    {!userFromContext?.isReadWriteAdministrator && !userFromContext.isReadOnlyAdministrator && (
                      <Grid container justifyContent="flex-end">
                        <Grid item>Welcome, {userFromContext?.userProfile ? userFromContext.userProfile.representativeName : "User"}</Grid>
                      </Grid>
                    )}
                    <Grid container justifyContent={isXSmall ? "flex-start" : "flex-end"}>
                      <Grid item onClick={handleLogout} style={{cursor:"pointer"}}>Logout</Grid>
                    </Grid>
                  </Grid>
                )}
                {(!userFromContext || !userFromContext.accessToken) && <Grid item xs={12} onClick={() => loginWithRedirect(GetLoginWithRedirectParams(RouteConstants.PortalRouter))} style={{cursor:"pointer"}}>Login</Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
