import {UserProfileType} from "./UserProfile";
import { VerificationStatus } from "./VerificationStatus";

export default class OrganizationForExport {
    OrganizationName: string = "";
    RepresentativeName: string = "";
    VerificationStatus: string = "";
    FederalEin: string = "";
    Address: string = "";
    PhoneNumber: string = "";
    Email: string = "";
    Website: string = "";
  }

  export const ConvertFromUserProfileType = (organizationProfile: UserProfileType) => {
    return {
        OrganizationName: organizationProfile.organizationName,
        RepresentativeName: organizationProfile.representativeName,
        Email: organizationProfile.emailAddress,
        VerificationStatus: VerificationStatus[organizationProfile.verificationStatus],
        FederalEin: organizationProfile.federalEIN,
        Address: organizationProfile.streetAddress1 + ", " + organizationProfile.streetAddress2 + ", " + organizationProfile.city + ", " + organizationProfile.state + ", " + organizationProfile.zipCode,
        PhoneNumber: organizationProfile.phoneNumber,
        Website: organizationProfile.website
    } as OrganizationForExport
  }