import React, { useContext, useEffect, useState } from "react";
import DownloadLink from "react-download-link";
import { styled } from "@mui/material/styles";
import { Box, Button, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { deleteUpload, getListOfFilesFromS3Key, updateAssistanceRequestFileName, uploadFilesToS3 } from "../../Clients";
import { AuthContext } from "../Authentication";
import FileListContainer from "../shared/FileListContainer";
import DescriptionIcon from '@mui/icons-material/Description';

export interface IAssistanceRequestDocumentation {
  assistanceRequestId: string;
  assistanceRequestS3Key?: string;
  isReadonly?: boolean;
  onDocumentationUpdated: (requestId: string, newS3Key: string, fileList: File[]) => Promise<void>;
}

const ModalStyle = styled(Grid)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "50vw",
  border: "1px solid black",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 4, 3),
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.down("md")]: {
    maxWidth: "80vw",
  },
}));

const Titles = styled('span')(() => ({
  fontWeight: "bold",
}));

const Links = styled(Grid)(() => ({}));

const Values = styled('span')(() => ({}));

const AssistanceRequestDocumentation: React.FC<IAssistanceRequestDocumentation> = ({ assistanceRequestId, assistanceRequestS3Key, isReadonly, onDocumentationUpdated }) => {
  const [newFileList, setNewFileList] = useState([] as File[]);
  const [currentFileList, setCurrentFileList] = useState([] as File[]);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const userFromContext = useContext(AuthContext);

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    const fetchRequestDocs = async () => {
      if (assistanceRequestS3Key && userFromContext?.accessToken) {
        setDownloadLoading(true);
        const requestedFiles = await getListOfFilesFromS3Key(userFromContext?.accessToken, assistanceRequestS3Key);
        setCurrentFileList(requestedFiles);
        setDownloadLoading(false);
      }
    };
    fetchRequestDocs();
  }, [userFromContext, assistanceRequestS3Key]);

  const fileUploadChange = (e: any) => {
    const passedFileArray = Array.from(e.target.files) as File[];
    setNewFileList(passedFileArray);
  };

  const deleteFile = (index: number) => {
    const files = [...newFileList];
    files.splice(index, 1);
    setNewFileList(files);
  };

  const handleClose = () => {
    setOpenUploadModal(false);
    setNewFileList([] as File[]);
  };

  const saveButtonClicked = async () => {
    if (newFileList.length > 0) {
      setUploadLoading(true);
      const newS3Key = await uploadFilesToS3(userFromContext?.accessToken!, currentFileList.concat(newFileList));
      if (assistanceRequestS3Key) {
        await deleteUpload(userFromContext?.accessToken!, assistanceRequestS3Key);
      }
      await updateAssistanceRequestFileName(userFromContext?.accessToken!, newS3Key, assistanceRequestId);
      onDocumentationUpdated(assistanceRequestId, newS3Key, newFileList).then(() => {
        setCurrentFileList(currentFileList.concat(newFileList));
        setNewFileList([] as File[]);
        setUploadLoading(false);
      });
    }
    handleClose();
  };

  return (
    <>
      <Modal open={openUploadModal} onClose={handleClose}>
        <ModalStyle container>
          <Grid item xs={12}>
            <Typography variant="h5" id="simple-modal-title">
              Upload Documentation
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <span>Upload any documentation to support this request (receipts, bills, etc).</span>
              <Button variant="contained" component="label" htmlFor="raised-button-file" style={{ marginLeft: "1em" }}>
                Upload
                <input type="file" hidden id="raised-button-file" multiple onChange={fileUploadChange} />
              </Button>
            </Grid>
            {newFileList && <FileListContainer fileList={newFileList} onDelete={deleteFile} />}
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" disabled={newFileList.length === 0} onClick={saveButtonClicked}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          {uploadLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bgcolor="rgba(255, 255, 255, 0.8)"
              zIndex={1000}
            >
              <CircularProgress />
            </Box>
          )}
        </ModalStyle>
      </Modal>
      <Grid item xs={12}>
        <Titles>Documentation: </Titles>
        {downloadLoading && <CircularProgress />}
        {!downloadLoading && currentFileList.length > 0 && (
          <Grid container style={{ paddingLeft: "2em" }}>
            {currentFileList.map((file: File) => {
              return (
                <Links xs={12} item key={file.name}>
                  <DescriptionIcon />
                  <DownloadLink label={file.name.slice(file.name.indexOf("_") + 1)} filename={file.name} exportFile={() => file} />
                </Links>
              );
            })}
          </Grid>
        )}
        {!downloadLoading && currentFileList.length === 0 && <Values>None</Values>}
      </Grid>
      <Grid item>
        {!isReadonly && (
          <Button onClick={() => setOpenUploadModal(true)}>
            Add Documentation
          </Button>
        )}
      </Grid>
    </>
  );
};
export default AssistanceRequestDocumentation;
