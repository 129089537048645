import React, { useState, useEffect, useContext } from "react";
import { UserProfileType, createUserProfileType } from "../../Domain/UserProfile";
import { AuthContext } from "../Authentication";
import { getS3Item } from "../../Clients";
import { Grid } from "@mui/material";
import DownloadLink from "react-download-link";
import { TaxationForm } from "../../Domain/TaxationForm";
import LabelAndTextDisplay from "../shared/LabelAndTextDisplay";
import LabelAndAnyDisplay from "../shared/LabelAndAnyDisplay";

const UserProfile: React.FC = () => {
  const userFromContext = useContext(AuthContext);

  const [profile, setProfile] = useState<UserProfileType>(createUserProfileType());
  const [currentTaxationForm, setCurrentTaxationForm] = useState<TaxationForm | undefined>()

  useEffect(() => {
    if (userFromContext?.userProfile) {
      if(userFromContext?.accessToken && userFromContext.userProfile.s3Key && userFromContext.userProfile.s3Key !== ""){
        getS3Item(userFromContext.accessToken, userFromContext.userProfile.s3Key).then(response => {
          setCurrentTaxationForm({s3Key: userFromContext.userProfile?.s3Key, file: response} as TaxationForm);
          setProfile(userFromContext.userProfile!);
        });
      }
      else {              
        setProfile(userFromContext.userProfile);
      }
    }
  }, [userFromContext]);

  function GetLabelForDownloadLink(form?: TaxationForm): string {
    var indexOfUnderscore = form?.s3Key?.indexOf("_") ?? -1;
    if(indexOfUnderscore === -1){
      return form?.s3Key ?? "";
    }
    return form?.s3Key?.slice(indexOfUnderscore + 1) ?? "";
  }

  function GetFileNameForDownload(form?: TaxationForm): string {
    return form?.s3Key ?? "defaultFileName";
  }

  return (
    <Grid container style={{padding:"2em"}} justifyContent="flex-start">
        <Grid item xs={12}>
            <Grid container>
                <LabelAndTextDisplay label="Email Address" text={userFromContext?.emailAddress}/>
                <LabelAndTextDisplay label="Organization Name" text={profile.organizationName}/>
                <LabelAndTextDisplay label="Federal ID (EIN)" text={profile.federalEIN}/>
                <LabelAndTextDisplay label="Representative Name" text={profile.representativeName}/>
                <LabelAndTextDisplay label="Phone Number" text={profile.phoneNumber}/>
                <LabelAndTextDisplay label="Website" text={profile.website}/>
                <LabelAndTextDisplay label="Address" text={[profile.streetAddress1, profile.streetAddress2, `${profile.city}, ${profile.state} ${profile.zipCode}`]}/>
            </Grid>
            <Grid container>
              <LabelAndAnyDisplay label="Tax Documentation" value={<DownloadLink
                    label={GetLabelForDownloadLink(currentTaxationForm)}
                    filename={GetFileNameForDownload(currentTaxationForm)}
                    exportFile={() => currentTaxationForm?.file}
                />} />
            </Grid>
        </Grid>
    </Grid>
  );
};

export default UserProfile;
