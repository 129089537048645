import { DonationAssistanceMonth } from "../Domain/Donation";

export function getAllMonthsForAYearFromStartDate(startMonth: number, startYear: number): DonationAssistanceMonth[] {
    const donationMonths: DonationAssistanceMonth[] = [];
    for (let i = 0; i < 12; i++) {
        const month = ((startMonth + i) % 12) || 12;
        const year = startYear + Math.floor((startMonth + i - 1) / 12);

        donationMonths.push({
            monthNumber: month,
            yearNumber: year,
            monthName: getMonthName(month),
            donationsRecieved: 0,
            assistanceSent: 0
        });
    }
    return donationMonths;
}

export function getAllMonthsForAGivenYear(year: number):  DonationAssistanceMonth[] {
    const donationMonths: DonationAssistanceMonth[] = [];
    for (let i = 1; i <= 12; i++) {
        donationMonths.push({
            monthNumber: i,
            yearNumber: year,
            monthName: getMonthName(i),
            donationsRecieved: 0,
            assistanceSent: 0
        });
    }
    return donationMonths;
}

function getMonthName(month: number): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (month < 1 || month > 12) {
        throw new Error("Invalid month");
    }
    return monthNames[month - 1];
}

