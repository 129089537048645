import React from "react";
import { Grid, Paper, Table, TableBody, TableRow, TableCell, TableHead, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { getNumericAsCurrencyString } from "../../Helpers/StringHelpers";
import { sumField, countUnique, getUniqueList } from "../../Helpers/ArrayHelpers";
import CustomCircularProgress from "../shared/CustomCircularProgress";

const ButterHeaderText = styled(Grid)(() => ({
  fontWeight: "bold",
  padding: "1em",
}));

const StyledPaper = styled(Paper)(() => ({
  padding: "1em",
}));

interface IAssistanceSummary {
  assistanceRequests: AssistanceRequest[];
  isLoading: boolean;
}

const AssistanceSummary: React.FC<IAssistanceSummary> = ({ assistanceRequests, isLoading }) => {
  return (
    <StyledPaper>
      {isLoading && <CustomCircularProgress message="Loading assistance totals" />}
      {!isLoading && (
        <Grid item>
          <Box>
            <Grid container justifyContent="center" style={{ padding: "1em" }}>
              <ButterHeaderText item xs={12}>
                Assistance Summary
              </ButterHeaderText>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Organizations ({countUnique(assistanceRequests, "organizationName")})</TableCell>
                      <TableCell>Requests Granted ({getNumericAsCurrencyString(sumField(assistanceRequests, "amountRequested"))})</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUniqueList(assistanceRequests, "organizationName").map(OrganizationName => {
                      const amountRequested = sumField(assistanceRequests.filter(ar => ar.organizationName === OrganizationName), "amountRequested");
                      return (
                        <TableRow key={OrganizationName + "tablerowkey"}>
                          <TableCell>{OrganizationName}</TableCell>
                          <TableCell>{getNumericAsCurrencyString(amountRequested)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </StyledPaper>
  );
};

export default AssistanceSummary;