import React from 'react';
import { Member } from '../../Domain/BoardMember';
import BoardMemberCard from './BoardMemberCard';
import { Grid, styled } from '@mui/material';

interface BoardMembersProps {
  members: Member[];
}

const BoardMembersGridContainer = styled(Grid)(() => ({
  textAlign: "center",
  padding: "20px"
}));

const MemberCardGridItem = styled(Grid)(() => ({
  minHeight:"40vh",
}));

const BoardMembers: React.FC<BoardMembersProps> = ({ members }) => {

  return (
    <BoardMembersGridContainer container justifyContent="flex-start" alignContent="flex-start" spacing={1}>
      <Grid item xs={12}>
        <h1>Our Board Members</h1>
      </Grid>
        {members.map((member, index) => (
          <MemberCardGridItem item xs={12} sm={6} lg={3} >
            <BoardMemberCard key={index} member={member} />
          </MemberCardGridItem>
        ))}
    </BoardMembersGridContainer>
  );
};

export default BoardMembers;