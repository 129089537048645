import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import StandardNavBar from "./StandardNavBar";
import MobileNavBar from "./MobileNavBar";

const ToolbarGridItem = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 25,
  [theme.breakpoints.down("lg")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  padding: ".5em",
  paddingLeft: "1em",
  paddingRight: "1em",
  textTransform: "uppercase",
  color: "white",
}));

const NavBarContainer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <ToolbarGridItem item xs={12}>
        {isMobile ? <MobileNavBar /> : <StandardNavBar />}
      </ToolbarGridItem>
    </Grid>
  );
};

export default NavBarContainer;
