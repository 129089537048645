import React from "react";
import AssistanceRequestReport from "./AssistanceRequestReport";
import { Grid } from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { ButterCampaign, ButterTransaction, ButterPlan } from "../../Domain/ButterInfo";
import { UserProfileType } from "../../Domain/UserProfile";
import CustomCircularProgress from "../shared/CustomCircularProgress";
import ButterTransactionReport from "./ButterTransactionReport";

interface IAdminReportsContainer {
    assistanceRequests?: AssistanceRequest[];
    organizations?: UserProfileType[];
    campaigns?: ButterCampaign[];
    transactions?: ButterTransaction[];
    plans?: ButterPlan[];
    isLoading: boolean;
}

const AdminReportsContainer: React.FC<IAdminReportsContainer> = ({assistanceRequests, organizations, campaigns, transactions, plans, isLoading}) => {
    return (
        <>
            {isLoading && (<CustomCircularProgress message="Loading report data" />)}
            {!isLoading && (
                <Grid container justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start" direction="column">
                    <Grid item display="inline-flex" alignItems="center" padding="1em">
                        <AssessmentIcon style={{paddingRight:".5em"}}/>
                        <AssistanceRequestReport assistanceRequests={assistanceRequests} reportName="Assistance Requests by Date Range" byDateRange/>
                    </Grid>
                    <Grid item display="inline-flex" alignItems="center" padding="1em">
                        <AssessmentIcon style={{paddingRight:".5em"}}/>
                        <AssistanceRequestReport assistanceRequests={assistanceRequests} reportName="Assistance Requests by Organization" byOrganization/>
                    </Grid>
                    <Grid item display="inline-flex" alignItems="center" padding="1em">
                        <AssessmentIcon style={{paddingRight:".5em"}}/>
                        <AssistanceRequestReport assistanceRequests={assistanceRequests} reportName="Assistance Requests by Organization and Date Range" byOrganization byDateRange/>
                    </Grid>
                    <Grid item display="inline-flex" alignItems="center" padding="1em">
                        <AssessmentIcon style={{paddingRight:".5em"}}/>
                        <ButterTransactionReport butterTransactions={transactions} byDateRange reportName="Butter Transaction Report by Date Range"/>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default AdminReportsContainer;
