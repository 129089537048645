import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IActionDrawerNoButton {
    drawerContent?: any;
    direction?: "top" | "bottom" | "left" | "right";
    open: boolean;
    close: () => void;
}

export const ActionDrawerNoButton: React.FC<IActionDrawerNoButton> = ({drawerContent, direction, open, close}) => {
  direction = direction ?? "right";

  const DrawerContents = () => (
    <Box
      sx={{
        width: {
          xs: '90vw', // 90% of the viewport width on extra small screens
          sm: '70vw', // 70% of the viewport width on small screens
          md: '50vw', // 50% of the viewport width on medium screens
          lg: '40vw', // 40% of the viewport width on large screens
          xl: '30vw', // 30% of the viewport width on extra large screens
        },
      }}
      role="presentation"
    >
      <div style={{padding: "1.5em"}}>{drawerContent}</div>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={direction}
        open={open}
        onClose={close}
      >
        <Grid container style={{paddingLeft: "3em", paddingTop:"3em", paddingRight:"1em"}}>
          <Grid item xs={12} style={{textAlign: "right"}}>
            <CloseIcon onClick={close} />
          </Grid>
          <Grid item xs={12}>
            {DrawerContents()}
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
}
