import AssistanceRequest from "./AssistanceRequest";

export default class AssistanceRequestForExport {
    OrganizationName: string = "";
    RepresentativeName: string = "";
    AmountRequested: number = 0.0;
    Purpose: string = "";
    RequestDate: string = "";
  }

  export const ConvertFromAssistanceRequest = (assistanceRequest: AssistanceRequest) => {
    return {
        OrganizationName: assistanceRequest.organizationName,
        RepresentativeName: assistanceRequest.representativeName,
        AmountRequested: assistanceRequest.amountRequested,
        Purpose: assistanceRequest.purpose,
        RequestDate: assistanceRequest.requestDate
    } as AssistanceRequestForExport
  }