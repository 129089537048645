export class StringType {
    S: string;
    constructor(s: string) {
      this.S = s;        
    }
}

export class NumberType {
  N: number;
  constructor(n: number) {
    this.N = n;        
  }
}

export class BooleanType {
  BOOL: boolean;
  constructor(b: boolean) {
    this.BOOL = b;        
  }
}

export class NullType {
    NULL: boolean;
    constructor(isNull: boolean) {
      this.NULL = isNull;        
    }
}

export const checkString = (check: StringType | NullType): string => {
  try {
    var stringTypeToCheck = check as StringType;
    return stringTypeToCheck.S
  }
  catch {
    return "";
  }
}

export const checkNumber = (check: NumberType | NullType): number => {
  try {
    var stringTypeToCheck = check as NumberType;
    return Number(stringTypeToCheck.N);
  }
  catch {
    return 0;
  }
}

export const checkBoolean = (check: BooleanType | NullType): boolean => {
  try {
    var stringTypeToCheck = check as BooleanType;
    return stringTypeToCheck.BOOL
  }
  catch {
    return false;
  }
}

export function checkEnum<T>(check: NumberType | NullType): T | undefined {
  try {
    var enumTypeCheck = check as NumberType;
    return (Number(enumTypeCheck.N)) as T;
  }
  catch {
    return undefined;
  }
}