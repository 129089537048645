import { NullType, NumberType, StringType, checkNumber } from "./DynamoDbDomain";
import { VerificationStatus } from "./VerificationStatus";

export type UserProfileType = {
  Id: string;
  organizationName: string;
  federalEIN: string;
  representativeName: string;
  phoneNumber: string;
  website: string | undefined;
  streetAddress1: string;
  streetAddress2: string | undefined;
  city: string;
  state: string;
  zipCode: string;
  s3Key: string;
  verificationStatus: VerificationStatus;
  emailAddress: string;
}

export type UserProfileViewModel = {
  Id: string;
  organizationName: string;
  federalEIN: string;
  representativeName: string;
  phoneNumber: string;
  website: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  verificationStatus: VerificationStatus;
  emailAddress: string;
  files: File[]
}

type UserProfileServerModel = {
  Id: StringType,
  organizationName: StringType | NullType,
  federalEIN: StringType | NullType,
  representativeName: StringType | NullType,
  phoneNumber: StringType | NullType,
  website: StringType | NullType,
  streetAddress1: StringType | NullType,
  streetAddress2: StringType | NullType,
  city: StringType | NullType,
  state: StringType | NullType,
  zipCode: StringType | NullType,
  s3Key: StringType | NullType,
  verificationStatus: NumberType | NullType,
  emailAddress: StringType | NullType
}

export const ConvertServerModelToUserProfile = (serverModel: UserProfileServerModel): UserProfileType => {
  const checkString = (check: StringType | NullType) => {
    try {
      var stringTypeToCheck = check as StringType;
      return stringTypeToCheck.S
    }
    catch {
      return "";
    }
  }

  return {
    Id: serverModel.Id.S,
    organizationName: checkString(serverModel.organizationName),
    federalEIN: checkString(serverModel.federalEIN),
    representativeName: checkString(serverModel.representativeName),
    phoneNumber: checkString(serverModel.phoneNumber),
    website: checkString(serverModel.website),
    streetAddress1: checkString(serverModel.streetAddress1),
    streetAddress2: checkString(serverModel.streetAddress2),
    city: checkString(serverModel.city),
    state: checkString(serverModel.state),
    zipCode: checkString(serverModel.zipCode),
    s3Key: checkString(serverModel.s3Key),
    verificationStatus: checkNumber(serverModel.verificationStatus),
    emailAddress: checkString(serverModel.emailAddress)
  } as UserProfileType
}


export function createUserProfileType():UserProfileType {
  return {
    organizationName: "",
    representativeName:"",
    federalEIN:"",
    streetAddress1:"",
    city:"",
    state:"",
    zipCode:"",
    website: "",
    streetAddress2: "",
    verificationStatus: VerificationStatus.New,
    emailAddress: ""
  } as UserProfileType;
}