import { CircularProgress, Grid } from "@mui/material";

export interface ICustomCircularProgress {
    message?: string;
    messagePosition?: "above" | "below"
}

const CustomCircularProgress: React.FC<ICustomCircularProgress> = ({message, messagePosition}) => {  
    messagePosition = message ? messagePosition ?? "below" : undefined;  
    return (
        <Grid item xs={12} alignSelf="center" style={{padding:"1em", height:"100%"}}>
            <Grid container justifyContent="center">
                {messagePosition === "above" && (<Grid item>{message}...</Grid>)}
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
                {messagePosition === "below" && (<Grid item>{message}...</Grid>)}
            </Grid>
        </Grid>
    );
};

export default CustomCircularProgress;
