import React, { useContext, useEffect, useState } from "react";
import DownloadLink from "react-download-link";
import { styled } from "@mui/material/styles";
import { UserProfileType } from "../../Domain/UserProfile";
import { CircularProgress, Grid } from "@mui/material";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { TaxationForm } from "../../Domain/TaxationForm";
import { getS3Item, updateUserVerification } from "../../Clients";
import { AuthContext } from "../Authentication";
import { ButtonType } from "../shared/ButtonType";
import ButtonGroupWithLoading from "../shared/ButtonGroupWithLoading";

const AddressStackContainer = styled('div')(() => ({
  paddingLeft: "1em",
}));

const Titles = styled('span')(() => ({
  fontWeight: "bold",
}));

const Values = styled('span')(() => ({}));

export interface IOrganizationDrawerContent {
  userProfile: UserProfileType;
  onStatusChange: (userId: string, status: VerificationStatus) => Promise<void>;
}

const OrganizationDrawerContent: React.FC<IOrganizationDrawerContent> = ({ userProfile, onStatusChange }) => {
  const userFromContext = useContext(AuthContext);
  const [taxationForm, setTaxationForm] = useState<TaxationForm | undefined>();
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>();
  const [isLoadingTaxForm, setIsLoadingTaxForm] = useState<boolean>();

  useEffect(() => {
    if (userFromContext && userFromContext.accessToken && userProfile.s3Key) {
      setIsLoadingTaxForm(true);
      getS3Item(userFromContext.accessToken, userProfile.s3Key).then(response => {
        setTaxationForm({ s3Key: userProfile.s3Key, file: response } as TaxationForm);
        setIsLoadingTaxForm(false);
      });
    }
    if (userFromContext?.isReadOnlyAdministrator) {
      setIsReadOnly(true);
    }
  }, [userFromContext, userProfile.s3Key]);

  function GetLabelForDownloadLink(form?: TaxationForm): string {
    const indexOfUnderscore = form?.s3Key?.indexOf("_") ?? -1;
    if (indexOfUnderscore === -1) {
      return form?.s3Key ?? "";
    }
    return form?.s3Key?.slice(indexOfUnderscore + 1) ?? "";
  }

  function GetFileNameForDownload(form?: TaxationForm): string {
    return form?.s3Key ?? "defaultFileName";
  }

  const onButtonClick = async (newStatus: VerificationStatus) => {
    setIsUpdatingStatus(true);
    await updateUserVerification(userFromContext?.accessToken!, userProfile.Id, newStatus);
    onStatusChange(userProfile.Id, newStatus)
      .then(() => setIsUpdatingStatus(false));
  };

  const newOrgButtonList = [
    { label: "Approve", onClick: () => onButtonClick(VerificationStatus.Approved) } as ButtonType,
    { label: "Deny", onClick: () => onButtonClick(VerificationStatus.Denied), color: "error" } as ButtonType,
  ] as ButtonType[];

  const currentOrgButtonList = [
    { label: "Revoke Privileges", onClick: () => onButtonClick(VerificationStatus.Denied), color: "error" } as ButtonType
  ] as ButtonType[];

  const previouslyDeniedOrgButtonList = [
    { label: "Approve", onClick: () => onButtonClick(VerificationStatus.Approved) } as ButtonType
  ] as ButtonType[];

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Titles>Organization:{" "}</Titles>
          <Values>{userProfile.organizationName}</Values>
        </Grid>
        <Grid item xs={12}>
          <Titles>Federal EIN:{" "}</Titles>
          <Values>{userProfile?.federalEIN}</Values>
        </Grid>
        {userProfile.website && (
          <Grid item xs={12}>
            <Titles>Website:{" "}</Titles>
            <Values>{userProfile?.website}</Values>
          </Grid>
        )}
        <Grid item xs={12}>
          <Titles>Representative:{" "}</Titles>
          <Values>{userProfile.representativeName}</Values>
        </Grid>
        <Grid item xs={12}>
          <Titles>Address:{" "}</Titles>
          <AddressStackContainer>
            <Values style={{ display: "block" }}>{userProfile?.streetAddress1}</Values>
            {userProfile?.streetAddress2 && (
              <Values style={{ display: "block" }}>{userProfile?.streetAddress2}</Values>
            )}
            <Values style={{ display: "block" }}>{userProfile?.city}, {userProfile?.state} {userProfile?.zipCode}</Values>
          </AddressStackContainer>
        </Grid>
        <Grid item xs={12}>
          <Titles>Phone Number:{" "}</Titles>
          <Values>{userProfile?.phoneNumber}</Values>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <Titles>Tax File:</Titles>
            </Grid>
            <Grid item style={{ paddingRight: ".5em" }} />
            {isLoadingTaxForm && <CircularProgress />}
            <Grid item>
              <DownloadLink
                label={GetLabelForDownloadLink(taxationForm)}
                filename={GetFileNameForDownload(taxationForm)}
                exportFile={() => taxationForm?.file}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isReadOnly && (
          <Grid item xs={12} style={{ paddingTop: "3em" }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ButtonGroupWithLoading
                  buttons={
                    userProfile.verificationStatus === VerificationStatus.New
                      ? newOrgButtonList
                      : userProfile.verificationStatus === VerificationStatus.Approved
                        ? currentOrgButtonList
                        : previouslyDeniedOrgButtonList
                  }
                  isLoading={isUpdatingStatus}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default OrganizationDrawerContent;