import React, { useContext, useEffect } from "react";
import { AuthContext } from "../Authentication";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../Constants/RouteConstants";
import { VerificationStatus } from "../../Domain/VerificationStatus";

const PortalRouter: React.FC = () => {
  const userFromContext = useContext(AuthContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    //once userfromcontext is set, it is no longer loading, and we can navigate the user to the correct place
    if(!userFromContext?.isLoading){
      if(!userFromContext?.accessToken){ 
        //if the user does not have an access token that means that have not signed up or have not signed in yet, and should be redirected to the "not registered" screen
        navigate(RouteConstants.NotRegistered);
      }
      else if(userFromContext?.accessToken && (userFromContext?.isReadOnlyAdministrator || userFromContext.isReadWriteAdministrator)){
        //if the user has an access token and is set as isAdmin = true, then they can be redirected to the admin portal
        navigate(RouteConstants.Admin);
      }
      else if(userFromContext?.accessToken && userFromContext?.userProfile?.verificationStatus !== VerificationStatus.Approved){ 
        //these are users who are signed up but have no been approved or do not have a profile yet
        navigate(RouteConstants.RegistrationSteps);
      }
      else if(userFromContext?.accessToken && userFromContext?.userProfile?.verificationStatus === VerificationStatus.Approved){
        // all users who are approved can go straight to the portal
        navigate(RouteConstants.Portal);
      }
    }    
  }, [userFromContext, navigate]);

  return (
    <>Waiting for redirect...</>
  );
};

export default PortalRouter;
