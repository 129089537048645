import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import AdminAssistanceRequests from "./AdminAssistanceRequests";
import { AuthContext } from "../Authentication";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import {
    getAdminAssistanceRequests,
    getButterCampaigns,
    getButterPlans,
    getButterTransactions,
    getUserProfiles
} from "../../Clients";
import { ButterCampaign, ButterPlan, ButterTransaction } from "../../Domain/ButterInfo";
import { ApplicationStatus } from "../../Domain/ApplicationStatus";
import { UserProfileType } from "../../Domain/UserProfile";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { Organizations } from "./Organizations";
import AdminReportsContainer from "./AdminReportsContainer";
import CustomCircularProgress from "../shared/CustomCircularProgress";
import AdminDashboard from "./AdminDashboard";

const Administration: React.FC = () => {
    const user = useContext(AuthContext);
    const [assistanceRequestList, setAssistanceRequestList] = useState<AssistanceRequest[]>([]);
    const [currentButterCampaign, setCurrentButterCampaign] = useState<ButterCampaign[]>([]);
    const [butterPlanList, setButterPlanList] = useState<ButterPlan[]>([]);
    const [butterTransactionList, setButterTransactionList] = useState<ButterTransaction[]>([]);
    const [userProfileList, setUserProfileList] = useState<UserProfileType[]>([]);

    const [isLoadingButterData, setIsLoadingButterData] = useState(true);
    const [isLoadingAssistanceRequests, setIsLoadingAssistanceRequests] = useState(true);
    const [isLoadingUserProfiles, setIsLoadingUserProfiles] = useState(true);

    const [activeTabIndex, setActiveTabIndex] = useState<0 | 1 | 2 | 3>(0);

    useEffect(() => {
        if (user && (user.isReadOnlyAdministrator || user.isReadWriteAdministrator) && user.accessToken) {            
            const fetchButterData = async () => {
                setIsLoadingButterData(true);
                try {
                    const [plans, campaigns, transactions] = await Promise.all([
                        getButterPlans(user.accessToken!),
                        getButterCampaigns(user.accessToken!),
                        getButterTransactions(user.accessToken!)
                    ]);
                    setCurrentButterCampaign(campaigns);
                    setButterPlanList(plans);
                    setButterTransactionList(transactions);
                } catch (error) {
                    console.error("Failed to fetch Butter data:", error);
                } finally {
                    setIsLoadingButterData(false);
                }
            };

            const fetchUserProfiles = async () => {
                setIsLoadingUserProfiles(true);
                try {
                    const profiles = await getUserProfiles(user.accessToken!);
                    setUserProfileList(profiles);
                } catch (error) {
                    console.error("Failed to fetch user profiles:", error);
                } finally {
                    setIsLoadingUserProfiles(false);
                }
            };

            const fetchAdminAssistanceRequests = async () => {
                setIsLoadingAssistanceRequests(true);
                try {
                    const requests = await getAdminAssistanceRequests(user.accessToken!);
                    setAssistanceRequestList(requests);
                } catch (error) {
                    console.error("Failed to fetch admin assistance requests:", error);
                } finally {
                    setIsLoadingAssistanceRequests(false);
                }
            };

            fetchButterData();
            fetchUserProfiles();
            fetchAdminAssistanceRequests();
        }
    }, [user]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: 0 | 1 | 2 | 3) => {
        setActiveTabIndex(newValue);
    };

    const onApplicationStatusUpdate = async (
        requestId: string,
        status: ApplicationStatus,
        completionDate?: string,
        payoutNotes?: string
    ) => {
        setAssistanceRequestList(prevRequestList =>
            prevRequestList.map(req => {
                if (req.Id === requestId) {
                    return {
                        ...req,
                        status,
                        projectCompletionDate: status === ApplicationStatus.Completed ? completionDate : "",
                        payoutNotes
                    };
                }
                return req;
            })
        );
    };

    const onDocumentationUpdated = async (requestId: string, newS3Key: string) => {
        setAssistanceRequestList(prevRequestList =>
            prevRequestList.map(req => {
                if (req.Id === requestId) {
                    return {
                        ...req,
                        s3Key: newS3Key
                    };
                }
                return req;
            })
        );
    };

    const onProfileVerificationStatusUpdate = async (userId: string, status: VerificationStatus) => {
        setUserProfileList(prevProfileList =>
            prevProfileList.map(profile => {
                if (profile.Id === userId) {
                    profile.verificationStatus = status;
                    return profile;
                }
                return profile;
            })
        );
    };

    return (
        <div style={{ height: '100%' }}>
            {user?.isLoading && (
                <CustomCircularProgress message="Loading user info" />
            )}
            {!user?.isLoading && (user?.isReadOnlyAdministrator || user?.isReadWriteAdministrator) && (                
                <Grid container>
                    <Grid item xs={12} style={{ paddingTop: "2em" }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={activeTabIndex} onChange={handleTabChange} aria-label="admin page tabs">
                                    <Tab label="Dashboard" />
                                    <Tab label="Assistance Requests" />
                                    <Tab label="Organizations" />
                                    <Tab label="Reports" />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={activeTabIndex} index={0}>
                                <AdminDashboard
                                    assistanceRequestList={assistanceRequestList}
                                    userProfileList={userProfileList}
                                    allButterCampaigns={currentButterCampaign}
                                    butterTransactionList={butterTransactionList}
                                    butterPlanList={butterPlanList}
                                    isLoadingAssistanceRequests={isLoadingAssistanceRequests}
                                    isLoadingUserProfiles={isLoadingUserProfiles}
                                    isLoadingButterData={isLoadingButterData}
                                    onApplicationStatusUpdate={onApplicationStatusUpdate}
                                    onProfileVerificationStatusUpdate={onProfileVerificationStatusUpdate}
                                    onDocumentationUpdated={onDocumentationUpdated}
                                />
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTabIndex} index={1}>
                                <AdminAssistanceRequests
                                    requestList={assistanceRequestList}
                                    isLoading={isLoadingAssistanceRequests}
                                    onStatusUpdate={onApplicationStatusUpdate}
                                    onDocumentationUpdated={onDocumentationUpdated}
                                />
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTabIndex} index={2}>
                                <Organizations
                                    userProfiles={userProfileList}
                                    isLoading={isLoadingUserProfiles}
                                    onStatusChange={onProfileVerificationStatusUpdate}
                                />
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTabIndex} index={3}>
                                <AdminReportsContainer
                                    campaigns={currentButterCampaign}
                                    plans={butterPlanList}
                                    transactions={butterTransactionList}
                                    assistanceRequests={assistanceRequestList}
                                    organizations={userProfileList}
                                    isLoading={isLoadingAssistanceRequests || isLoadingButterData}
                                />
                            </CustomTabPanel>
                        </Box>
                    </Grid>
                </Grid>
            )}
            {!user?.isLoading && !user?.isReadOnlyAdministrator && !user?.isReadWriteAdministrator && (
                <>
                    You do not have permission to access this page, please{" "}
                    <a href="mailto:support@bethechangegulfcoast.com">email</a>{" "}
                    our support department if you believe this is in error.
                </>
            )}
        </div>
    );
};

export default Administration;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}