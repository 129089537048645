export default class SpeakingOpportunity {
  firstName: string = "";
  lastName: string = "";
  emailAddress: string = "";
  details: string = "";
  organizationName: string = "";
  dateRangeString: string = "";
  fromDate: Date | null = null;
  toDate: Date | null = null;
}
export class SpeakingOpportunityResponse {
  errorMessages: string[] = [];
  success: boolean = false;
}
